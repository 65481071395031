import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getMapProducts = createAsyncThunk(
    'mapProducts/getMapProducts',
    async ({ lat, lng, page }) => {
        const url = `${process.env.REACT_APP_BASE_URL}/products/get-map-products`;
        const headers = { 'Content-Type': 'application/json' };
        const options = { method: 'POST', headers, body: JSON.stringify({ userLat: lat, userLng: lng, page }) };
        const response = await fetch(url, options);
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            const error = await response.json();
            throw new Error(error.message || "Errore sconosciuto")
        }
    }
)

const mapProductsSlice = createSlice({
    name: 'mapProducts',
    initialState: {
        data: {
            totalElements: null,
            page: null,
            totalePages: null,
            productsPage: [],
            allProducts: null // Per ora arrivano tutti i prodotti
        },
        isLoading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMapProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getMapProducts.fulfilled, (state, action) => {
                state.data.totalElements = action.payload.payload.totalElements;
                state.data.totalePages = action.payload.payload.totalePages;
                // Se arriva la prima pagina sostituisco l'array, altrimenti aggiungo il nuovo array alle pagine esistenti
                if (action.payload.payload.page === 1) {
                    state.data.productsPage = action.payload.payload.productsPage;
                } else {
                    if (action.payload.payload.page > state.data.page) {
                        state.data.productsPage = [
                            ...state.data.productsPage,
                            ...action.payload.payload.productsPage
                        ]
                    }
                };
                state.data.page = action.payload.payload.page;
                state.data.allProducts = action.payload.payload.allProducts;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getMapProducts.rejected, (state, action) => {
                state.error = action.error;
                state.isLoading = false;
            })
    }
})

export default mapProductsSlice.reducer;