import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const GoogleAnalytics = () => {
    const location = useLocation();

    useEffect(() => {
        if (typeof gtag === 'function') {
            window.gtag('config', 'AW-16653545350', {
                'page_path': location.pathname + location.search,
            })
        }
    }, [location])
    return null
}

export default GoogleAnalytics;
