import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import FetchLoader from '../FetchLoader';
import 'chartjs-adapter-date-fns';

const ChartCard = ({ registrationData }) => {
    const [chartData, setChartData] = useState({
        dates: [],
        counts: []
    })

    useEffect(() => {
        if (registrationData) {
            const dates = registrationData.map(element => element.date);
            const counts = registrationData.map(element => element.count);
            setChartData({ dates, counts })
        }
    }, [registrationData])

    const data = {
        labels: chartData.dates,
        datasets: [
            {
                label: 'Registrazioni giornaliere',
                data: chartData.counts,
                fill: false,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            }
        ]
    }

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'yyyy-MM-dd',
                    displayFormats: {
                        day: 'yyyy-MM-dd'
                    }
                },
                title: {
                    display: true,
                    text: 'Data'
                }
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Numero di registrazioni'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top'
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.parsed.y} registrazioni`;
                    }
                }
            }
        }
    };

    return (
        <div className='w-full'>
            <h3>Registrazioni giornaliere degli utenti dal {chartData.dates[0]}</h3>
            <Line data={data} options={options} />
        </div>
    )
}

export default ChartCard;
