import { useState, useEffect } from "react";
import FetchLoader from '../FetchLoader.jsx';
import UserAvatar from "../UserAvatar.jsx";

const RequestProductCard = ({ productData }) => {
    useEffect(() => {
        console.log('productData: ', productData)
    }, [productData])
    return (
        <div className='flex flex-col w-full border-2 p-4 gap-4'>
            <div className="flex flex-col md:flex-row gap-4">
                {/* left-photo */}
                <div className="overflow-hidden w-24 h-24">
                    <img src={productData.photo.resizedUrl} alt="product photo" className="w-full h-full object-cover rounded-lg" />
                </div>

                {/* right-info */}
                <div className="text-left flex flex-col gap-2 w-full">
                    <h4>ID prodotto: {productData._id}</h4>
                    <h4>A <span className='text-red-400'>{Math.round(productData.distance / 1000)}</span> km da te</h4>
                    <div className="text-sm text-teal-500 mb-[-10px]">Titolo prodotto</div>
                    <div>{productData.title}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Categoria</div>
                    <div>{productData.category}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Prezzo</div>
                    <div>{productData.price}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Condizioni prodotto</div>
                    <div>{productData.wornStatus}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Descrizione</div>
                    <div>{productData.description}</div>
                </div>

            </div>

        </div>
    )
}

export default RequestProductCard;