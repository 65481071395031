import { useState, useEffect } from "react";
import FetchLoader from '../FetchLoader.jsx';
import UserAvatar from "../UserAvatar.jsx";
import { Link } from "react-router-dom";
import MainCard from "./MainCard.jsx";

const SoldProductCard = ({ productData }) => {
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [pickerData, setPickerData] = useState(null);
    const getPickerData = async () => {
        try {
            setFetchStatus('pending')
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/get-user-contacts/${productData.saleStatus.pickerId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                setPickerData(result.payload);
                setFetchStatus('succeeded');
            } else {
                const errorData = await response.json();
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error');
            }
        } catch (error) {
            console.error('Catch error: ', error);
            setFetchStatus('error');
        }
    }
    useEffect(() => {
        getPickerData();
    }, [])

    // Debug
    useEffect(() => {
        console.log('pickerData: ', pickerData)
    }, [pickerData])

    return (
        <div className='flex flex-col w-full border-2 p-4 gap-4'>
            <MainCard data={[productData._id, productData.coverImage.resizedUrl, productData.title, productData.category, productData.price]} />

            {/* Message */}
            <div className="bg-teal-100 p-2 flex flex-col gap-1 items-center pt-6">
                <h4>Ecco i dati dell'utente a cui hai venduto questo oggetto.</h4>
                <p><i>Contattalo telefonicamente o via email per accordarti sullo scambio e il pagamento.</i></p>
                <div className="text-sm text-teal-500 mb-[-10px]">Email:</div>
                {fetchStatus === 'succeeded' && <h3 className="text-teal-700"><Link to={`mailto:${pickerData.email}`}>{pickerData.email}</Link></h3>}
                <div className="text-sm text-teal-500 mb-[-10px]">Numero di telefono:</div>
                {pickerData && pickerData.phone && <h2 className="text-slate-50 bg-teal-500 rounded p-2 mt-2"><Link to={`https://wa.me/${pickerData.phone}`}>{pickerData.phone}</Link></h2>}
                {pickerData && !pickerData.phone && <h3 className="text-teal-700">L'utente non ha impostato nessun numero</h3>}

                {pickerData && pickerData.phone && <h4>Clicca sul numero di telefono per contattare l'utente</h4>}

            </div>

            {/* Picker data */}
            {
                fetchStatus === 'pending' ? (
                    <FetchLoader />
                ) : (
                    fetchStatus === 'error' ? (
                        <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>
                    ) : (
                        fetchStatus === 'succeeded' &&
                        <div className="flex gap-4 border-2 border-teal-200 p-4 mt-[-15px]">
                            {/* left-photo */}
                            <div>
                                <UserAvatar url={pickerData.avatarUrl} />
                            </div>

                            {/* right-info */}
                            <div className="text-left flex flex-col gap-2 w-full">
                                <h4>ID Utente: {productData.saleStatus.pickerId}</h4>
                                <div className="text-sm text-teal-500 mb-[-10px]">Nome</div>
                                <div>{pickerData.name}</div>
                                <div className="text-sm text-teal-500 mb-[-10px]">Cognome</div>
                                <div>{pickerData.surname}</div>
                                <div className="text-sm text-teal-500 mb-[-10px]">Si trova a</div>
                                <div>x km di distanza da te</div>

                            </div>
                        </div>
                    )
                )
            }
        </div>
    )
}

export default SoldProductCard;