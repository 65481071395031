import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersMainData } from '../redux/adminSlice';
import UserCard from '../components/adminComponents/UserCard.jsx';
import FetchLoader from '../components/FetchLoader.jsx';
import ChartCard from '../components/adminComponents/ChartCard.jsx';

const AdminPage = () => {
    const { isLoading, error, usersMainData, usersCount, registrationData } = useSelector((state) => state.admin)

    // Get all users main data
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getUsersMainData())
    }, [])

    return (
        <div className="main-container">
            <h2>Admin dashboard</h2>

            {
                isLoading && <FetchLoader />
            }

                <ChartCard registrationData={registrationData} />


            {
                usersMainData &&
                <div className="box w-full  md:shadow-xl">
                    <div className='flex flex-col gap-2 w-full items-center'>
                        {usersCount && <h2><span className='p-1 bg-teal-300 rounded'>{usersCount}</span> utenti totali</h2>}
                        <hr />
                        {
                            usersMainData && usersMainData.length !== 0 && usersMainData.map((user, index) => (
                                <UserCard key={index} index={index} userData={user} isEven={index % 2 === 0 ? true : false} />
                            ))
                        }
                    </div>
                </div>
            }
        </div >
    )
}

export default AdminPage;