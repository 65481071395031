import { useSelector } from "react-redux";
import { GoHomeButton, PrimaryButton } from "../components/Buttons";
import { useEffect, useState } from "react";
import FetchLoader from '../components/FetchLoader.jsx';

const SupportPage = () => {

    // Handle input data
    const { id, email } = useSelector(state => state.login)
    const [inputData, setInputData] = useState({
        userId: '',
        email: '',
        message: ''
    })
    useEffect(() => {
        if (id) {
            setInputData(prevState => ({
                ...prevState,
                userId: id,
                email
            }))
        }
    }, [id, email])
    const handleMessage = (event) => {
        const { value } = event.target;
        setInputData((prevState => ({
            ...prevState,
            message: value
        })))
    };
    useEffect(() => {
        console.log(inputData)
    }, [inputData])

    // handle send email fetch
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const sendEmail = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/support-email`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputData)
            });
            if(response.ok) {
                const result = await response.json();
                console.log('Fetch result: ', result);
                setFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.href = "/"
                }, 3000)
            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult);
                setFetchStatus('rejected');
            }
        } catch (error) {
            console.log('Catch error: ', error);
            setFetchStatus('rejected');
        }
    }
    return (
        <div className="main-container">
            <GoHomeButton />
                <h2 className="mb-4">Segnala un problema</h2>
            <div className="box md:shadow-2xl max-w-[600px]">
                <p>Grazie per essere qui e contribuire al miglioramento della tua esperienza su <span className="text-teal-500"><b>Tattooswap</b></span>. Siamo qui per garantire un servizio di qualità, ma capiamo che a volte possano verificarsi inconvenienti. <b>La tua segnalazione è fondamentale per noi</b>.</p>
                <p>Per favore, prenditi il tempo per fornirci <b>più dettagli possibili</b> sulla problematica che hai riscontrato. Ciò ci aiuterà non solo a risolvere il tuo problema nello specifico ma anche a implementare miglioramenti per i quali beneficerà l'intera comunità di <span className="text-teal-500"><b>Tattooswap</b></span>.</p>
                <p>Ricorda che siamo qui per te, e apprezziamo la tua collaborazione nel rendere <span className="text-teal-500"><b>Tattooswap</b></span> un luogo migliore per tutti.</p>
                <p>Grazie,
                    Il Team di Assistenza <span className="text-teal-500"><b>Tattooswap</b></span></p>

                <h3 className="mt-8">Inserisci le informazioni qui sotto</h3>
                <div className="flex flex-col items-start px-4 pb-4 border gap-2">
                    <label htmlFor="userId">ID utente</label>
                    <div>{id}</div>
                    <label htmlFor="message">Corpo del messaggio</label>
                    <textarea id="message" className="w-[300px] sm:w-[400px] md:w-[500px]" onChange={handleMessage} />
                    <p>(Assicurati di aver inserito tutte le informazioni)</p>
                    {fetchStatus === 'idle' && <div className="self-center md:self-auto"><PrimaryButton text="Invia" click={sendEmail} /></div>}
                    {fetchStatus === 'pending' && <FetchLoader />}
                    {fetchStatus === 'succeeded' && <h3 className="text-teal-500">Email inviata con successo, sarai reindirizzat* in homepage.</h3>}
                    {fetchStatus === 'rejected' && <h3 className="text-red-500">Qualcosa è andato storto, ricarica la pagina e riprova.</h3>}
                </div>
            </div>
        </div>
    )
}
export default SupportPage;