import { useParams } from "react-router-dom";
import { GoHomeButton, PrimaryButton } from "../components/Buttons";
import FetchLoader from "../components/FetchLoader";
import { useEffect, useState } from "react";

const SetNewPassword = () => {
    const { token } = useParams();
    // Handle input data
    const [inputData, setInputData] = useState({
        newPassword: ''
    })
    const handleInputData = (event) => {
        const { value } = event.target;
        setInputData(prevData => ({
            ...prevData,
            newPassword: value
        }))
    }
    // debug
    useEffect(() => {
        console.log(token)
    }, [token])
    // handle send data
    const [fetchStatus, setFetchStatus] = useState('idle');
    const sendData = async () => {
        try {
            const data = { newPassword: inputData.newPassword, token: token };
            setFetchStatus('pending')
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/set-new-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                setFetchStatus('fulfilled');
                setTimeout(() => {
                    window.location.href = "/"
                }, 3000);
            } else {
                const resultError = await response.json();
                console.log('Fetch error: ', resultError);
                setFetchStatus('rejected');
            }
        } catch (error) {
            console.log('Catch error: ', error);
            setFetchStatus('rejected');
        }
    }
    return (
        <div className="main-container">
            <GoHomeButton />
            <div className="box md:shadow-2xl">
                {
                    fetchStatus === 'idle' ? (
                        <>
                            <h3>Inserisci una nuova password</h3>
                            <label htmlFor="email">Password</label>
                            <input type="password" id="password" onChange={handleInputData} />
                            <div className='text-slate-500 text-xs self-start mb-8'>Inserisci una password sufficientemente complessa.</div>
                            <PrimaryButton text="Salva password" click={sendData} />
                        </>
                    ) : (
                        <>
                            {fetchStatus === 'pending' && <FetchLoader />}
                            {fetchStatus === 'rejected' && <h3>Qualcosa è andato storto, ricarica la pagine riprova.</h3>}
                            {
                                fetchStatus === 'fulfilled' &&
                                <>
                                    <h3>Password aggiornata con successo.</h3>
                                    <p>Verrai reindirizzat* in homepage fra 3 secondi.</p>
                                </>
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default SetNewPassword;