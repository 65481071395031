import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ShowDataCustom = () => {
    /* const { allProductsAmount, soldProductsAmount } = useSelector(state => state.products.data); */
    const allProductsAmount = 1546;
    const soldProductsAmount = 34

    const [count1Speed, setCount1Speed] = useState(0);
    const [count2Speed, setCount2Speed] = useState(0);
    useEffect(() => {
        if (allProductsAmount < 10) {
            setCount1Speed(100)
        }
        if (allProductsAmount > 10 && allProductsAmount <= 100) {
            setCount1Speed(50)
        }
        if (allProductsAmount > 100 && allProductsAmount <= 1000) {
            setCount1Speed(10)
        }
        if (allProductsAmount > 1000) {
            setCount1Speed(5)
        }
    }, [allProductsAmount])
    useEffect(() => {
        if (soldProductsAmount < 10) {
            setCount2Speed(100)
        }
        if (soldProductsAmount > 10 && soldProductsAmount <= 100) {
            setCount2Speed(50)
        }
        if (soldProductsAmount > 100 && soldProductsAmount <= 1000) {
            setCount2Speed(10)
        }
        if (soldProductsAmount > 1000) {
            setCount2Speed(5)
        }
    }, [soldProductsAmount])

    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);

    useEffect(() => {
        if (count1 < allProductsAmount) {
            setTimeout(() => {
                if (count1 <= 100) {
                    setCount1(count1 + 1)
                } else if (count1 > 100 && count1 <= 1000) {
                    setCount1(count1 + 5)
                } else if (count1 > 1000 && count1 <= 5000) {
                    setCount1(count1 + 10)
                } else {
                    setCount1(count1 + 50)
                }
            }, count1Speed)
        }
    }, [count1, count1Speed])
    useEffect(() => {
        if (count2 < soldProductsAmount) {
            setTimeout(() => {
                if (count2 <= 100) {
                    setCount2(count2 + 1)
                } else if (count2 > 100 && count2 <= 1000) {
                    setCount2(count2 + 5)
                } else if (count2 > 1000 && count2 <= 5000) {
                    setCount2(count2 + 10)
                } else {
                    setCount2(count2 + 50)
                }
            }, count2Speed)
        }
    }, [count2, count2Speed])

    return (
        <div className="flex flex-col gap-2 items-center">
            <div className="flex flex-col md:flex-row gap-2">
                <div>
                    <h1 className="text-red-500 text-6xl">{count1}</h1>
                </div>
                <div>
                    <h1>prodotti totali su Tattooswap.</h1>
                </div>
            </div>
            <div className="flex flex-col md:flex-row gap-2">
                <div>
                    <h1 className="text-red-500 text-6xl">{count2}</h1>
                </div>
                <div>
                    <h1>prodotti scambiati finora.</h1>
                </div>
            </div>
        </div>
    )
}

export default ShowDataCustom;