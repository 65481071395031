import { createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
    name: 'page',
    initialState: {
        selected: 0
    },
    reducers: {
        selectPage: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const { selectPage } = pageSlice.actions;

export default pageSlice.reducer;