import { useEffect, useState } from "react";
import { PrimaryButton } from "./Buttons";

const ProfileUserAvatar = ({ url, click }) => {
    const [mouseOver, setMouseOver] = useState(false);
    return (
        <>
            <div className="overflow-hidden rounded-3xl w-48 h-48 flex justify-center items-center relative">
                <img src={url} alt="avatar" className="h-full w-full object-cover md:cursor-pointer" onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)} onClick={click} />
                {mouseOver && <div className="hidden bg-teal-500 opacity-50 absolute top-0 right-0 bottom-0 left-0 pointer-events-none md:flex justify-center items-center text-white">
                    Modifica avatar
                </div>}
            </div>
            <div className="md:hidden"><PrimaryButton text="Modifica immagine" click={click} /></div>
        </>
    )
}

export default ProfileUserAvatar;