import { createSlice } from "@reduxjs/toolkit";

const mapFiltersSlice = createSlice({
    name: 'filters',
    initialState: {
        /* filtersObject: null, */
        page: 1
    },
    reducers: {
        /* setNewFilters: (state, action) => {
            const newFilters = action.payload;
            console.log('newFilters: ', newFilters);
            state.filtersObject = {
                ...state.filtersObject,
                ...newFilters
            }
        },
        emptyFilters: (state) => {
            state.filtersObject = {}
        }, */
        pageUp: (state) => {
            state.page = state.page + 1
        },
        pageDown: (state) => {
            state.page = state.page - 1
        },
        setPage: (state, action) => {
            state.page = action.payload
        }
    }
})

export const { /* setNewFilters, emptyFilters, */ pageUp, pageDown, setPage } = mapFiltersSlice.actions;
export default mapFiltersSlice.reducer;