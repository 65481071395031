import { Link } from "react-router-dom";

const PrivacyPage = () => {
    return (
        <div className="main-container">

            <Link to="/register"><div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white">
                <i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
                <h4>Torna alla registrazione</h4>
            </div></Link>

            <div className="box md:shadow-2xl">
                <div className="flex flex-col items-start text-start">
                    <h2>Privacy Policy</h2>
                    <h4>Informativa ai sensi del Regolamento UE 2016/679 (“GDPR”)</h4>
                    <ul className="text-start mt-8">
                        <li>
                            <h3>1. Quali tipi di dati raccogliamo</h3>
                            <p>
                                Quando usi i nostri servizi, accetti che la nostra azienda raccolga alcuni tuoi dati personali. Questa pagina ha lo scopo di dirti quali dati raccogliamo, perché e come li usiamo.
                                Trattiamo due tipi di dati:
                                <ul>
                                    <li className="style-disc">dati forniti dall’utente</li>
                                    <li>dati che raccogliamo automaticamente</li>
                                </ul>


                            </p>
                        </li>
                        <li>
                            <h3>1.1. Dati forniti dall’utente</h3>
                            <h4>Dati personali</h4>
                            <p>
                                Quando ti registri, ti chiediamo di fornirci alcuni dati che servono per poter usufruire del nostro servizio.

                                Questi sono, ad esempio, i dati che ti chiediamo:
                                <ul>
                                    <li>nome, email e password e numero di telefono cellulare</li>
                                    <li>altri dati relativi all’oggetto del tuo annuncio</li>
                                </ul>

                                Potrai modificare il tuo numero di telefono nelle impostazioni della tua area riservata.

                                Nell’ambito dell’utilizzo del servizio puoi usufruire della funzione “Messaggi Diretti” per comunicare con gli altri utenti. Per maggiori approfondimenti ti invitiamo a leggere la sezione “Messaggi Diretti”.

                            </p>
                            <h4>Dati di terzi</h4>
                            <p>
                                Se fornisci dati personali di terzi, come ad esempio quelli dei tuoi familiari o amici, devi essere sicuro che questi soggetti siano stati adeguatamente informati e abbiano acconsentito al relativo trattamento nelle modalità descritte dalla presente informativa.
                            </p>
                            <h4>Dati di minori di anni 16</h4>
                            <p>
                                Se hai meno di 16 anni non puoi fornirci alcun dato personale né puoi registrarti su TattooSwap, ed in ogni caso non assumiamo responsabilità per eventuali dichiarazioni mendaci da te fornite. Qualora ci accorgessimo dell’esistenza di dichiarazioni non veritiere procederemo con la cancellazione immediata di ogni dato personale acquisito.
                            </p>
                        </li>
                        <li>
                            <h3>1.2. Dati che raccogliamo automaticamente</h3>
                            <p>
                                Raccogliamo i seguenti dati mediante i servizi che utilizzi:
                                <ul>
                                    <li>dati tecnici: ad esempio indirizzo IP, tipo di browser, informazioni sul tuo computer, dati relativi alla posizione attuale (previa autorizzazione allo strumento che stai utilizzando);</li>
                                    <li>dati raccolti utilizzando i cookie o tecnologie similari: per ulteriori informazioni, ti invitiamo a visitare la sezione “Cookie”.</li>
                                </ul>
                            </p>
                        </li>
                        <li>
                            <h3>2. Come utilizziamo i dati raccolti</h3>
                            <p>
                                Utilizziamo i dati raccolti per offrirti ogni giorno il nostro servizio, per informarti sulle nostre attività commerciali o per proporti un servizio più personalizzato ed in linea con i tuoi interessi.
                            </p>
                            <h4>2.1. Per garantirti l’accesso ai nostri servizi e migliorarne l’erogazione</h4>
                            <p>
                                Utilizziamo i tuoi dati per garantirti l’accesso ai nostri servizi e la loro erogazione, tra cui:
                                <ul>
                                    <li>registrazione e creazione dell’area riservata.</li>
                                    <li>pubblicazione degli annunci</li>
                                    <li>utilizzo delle funzionalità “preferiti” e “ricerche salvate”</li>
                                    <li>comunicazioni connesse all’erogazione del servizio</li>
                                    <li>messaggistica tra utenti (Messaggi Diretti)</li>
                                </ul>

                                <p>
                                    Tali trattamenti sono necessari per erogare correttamente i servizi di TattooSwap nei confronti degli utenti che vi aderiscono.

                                </p>
                                <p>
                                    Utilizziamo i tuoi dati anche per assicurare un servizio migliore ed implementarlo, attraverso i seguenti trattamenti:
                                    <ul>
                                        <li>assistenza tramite la sezione “Segnala un prolema”</li>
                                        <li>analisi dei dati in forma aggregata</li>
                                        <li>revisione degli annunci, moderazione o rimozione dei contenuti, prevenzione delle frodi</li>
                                        <li>attività di natura amministrativa, finanziaria o contabile, come ad esempio quelle relative agli acquisti dei servizi opzionali a pagamento ed eventuale recupero del credito</li>
                                        <li>rilevamento della tua posizione attuale (previa autorizzazione allo strumento che stai utilizzando) per facilitare la fruizione di alcune funzioni del servizio, come ad esempio la visualizzazione degli annunci di utenti a te vicini</li>
                                        <li>comunicazioni inerenti a servizi analoghi a quelli da te utilizzati</li>
                                        <li>ricerche di mercato, sondaggi facoltativi e attività di rilevazione del grado di soddisfazione dell’utenza</li>
                                    </ul>
                                </p>

                                <p>
                                    Tali trattamenti si basano sul legittimo interesse del Titolare al miglioramento del servizio ed alla sua implementazione e puoi opporti, nei casi previsti dalla legge, in ogni momento.

                                </p>
                            </p>
                            <h4>2.2. Per informarti riguardo alle nostre attività commerciali</h4>
                            <p>
                                Utilizziamo i dati raccolti, per informarti riguardo ad attività promozionali che potrebbero interessarti.
                            </p>

                            <p>
                                In particolare li utilizziamo per:
                                <ul>
                                    <li>comunicarti attività promozionali, commerciali e pubblicitarie su eventi, iniziative o partnership di TattooSwap, tramite posta elettronica, invio SMS o notifiche push</li>
                                    <li>fare attività di analisi e di reportistica connessa ai sistemi di comunicazione promozionale, come ad esempio il rilevamento del numero delle e-mail aperte, dei click effettuati sui link presenti all’interno della comunicazione, la tipologia del dispositivo utilizzato per leggere la comunicazione ed il relativo sistema operativo o l’elenco dei disiscritti alla newsletter.</li>
                                </ul>
                            </p>
                            <h4>2.3. Per offrirti un servizio personalizzato</h4>
                            <p>
                                Elaboriamo i dati raccolti, per analizzare le tue abitudini o scelte di consumo al fine di proporti un servizio sempre più personalizzato ed in linea con i tuoi interessi e di migliorare la nostra offerta commerciale. Tali analisi non sono in ogni caso correlate ad un processo decisionale automatizzato.
                            </p>
                        </li>
                        <li>
                            <h3>3. Il conferimento dei dati è obbligatorio?</h3>
                            <p>
                                Il conferimento dei dati personali è obbligatorio esclusivamente per la registrazione della propria utenza al nostro servizio e la conseguente fruizione dei servizi offerti da TattooSwap.
                            </p>
                        </li>
                        <li>
                            <h3>4. Chi sono i soggetti del trattamento?</h3>
                            <h4>4.1. Titolare del trattamento</h4>
                            <p>
                                Titolare del trattamento è Dormouse s.n.c. di Ferrari Monica & C. in persona del suo legale rappresentante pro-tempore, con sede legale sita in San Martino in Rio (RE), Italia, Via G. Magnanini 5, P.I. e C.F. 02659650358.

                                Il titolare del trattamento si avvale di responsabili del trattamento per il raggiungimento delle finalità specificate al punto 2 e di un Data Protection Officer (DPO) per vigilare sulla tutela relativa ai dati personali.

                                Per ogni richiesta relativa ai tuoi dati personali confronta il successivo punto 4.2.
                            </p>
                            <h4>4.2. Data Protection Officier (DPO) e informazioni di contatto</h4>
                            <p>
                                Il responsabile designato per la protezione dei dati personali (Data Protection Officer) ai sensi dell’art. 37 del GDPR è Fausto Costi.

                                Ti ricordiamo che puoi in qualsiasi momento contattare il DPO ed inviare qualsiasi domanda o richiesta relativa ai tuoi dati personali e al rispetto della tua privacy scrivendo all’indirizzo info@tattooswap.it.
                            </p>
                            <h4>4.3. Soggetti a cui possono essere comunicati dati personali</h4>
                            <p>
                                I dati raccolti nell’ambito dell’erogazione del servizio potranno essere comunicati a:
                                <li>società che svolgono funzioni strettamente connesse e strumentali all’operatività – anche tecnica – dei servizi di TattooSwap, quali ad esempio fornitori che erogano servizi finalizzati alla revisione e alla verifica degli annunci, fornitori di servizi di direct marketing e di customer care, società che erogano servizi di archiviazione, amministrativi, di pagamento e fatturazione, società collegate che forniscono componenti tecniche per l’erogazione di alcune funzionalità del servizio</li>
                                <li>enti ed autorità amministrative e giudiziarie in virtù degli obblighi di legge</li>

                                I tuoi dati personali potrebbero essere trasferiti al di fuori dell’Unione Europea per essere trattati da alcuni dei nostri fornitori di servizi. In questo caso, ci assicuriamo che questo trasferimento avvenga nel rispetto della legislazione vigente e che sia garantito un livello adeguato di protezione dei dati personali basandoci su una decisione di adeguatezza, su clausole standard definite dalla Commissione Europea o su Binding Corporate Rules.
                            </p>
                        </li>
                        <li>
                            <h3>5. Come puoi avere informazioni sui dati, modificarli, cancellarli o averne una copia?</h3>
                            <h4>5.1. Accesso ai dati personali e revoca consenso</h4>
                            <p>
                                Puoi, in qualsiasi momento, richiedere copia dei tuoi dati in nostro possesso, scrivendo a info@tattooswap.it.

                                Puoi revocare in qualsiasi momento i consensi che hai fornito, scrivendo a info@tattooswap.it.

                                Per la revoca del consenso relativo al tracciamento tramite cookie di profilazione e/o tecnologie similari, segui le istruzioni presenti nella sezione “Cookie”.
                            </p>
                            <h4>5.2. Esportazione e cancellazione dei dati personali</h4>
                            <p>
                                Per esportare i tuoi dati personali (takeout) o chiederne la cancellazione puoi inviare una richiesta all’indirizzo email info@tattooswap.it dalla casella di posta elettronica con cui ti sei registrato in TattooSwap.

                                I tuoi dati personali saranno esportati entro 30 giorni o, nel caso in cui l’esportazione si renda particolarmente complessa, entro tre mesi.

                                La cancellazione verrà effettuata nei tempi tecnici previsti ed in conformità al periodo di conservazione esplicitato nel successivo punto 6.
                            </p>
                            <h4>5.3. Esercizio dei tuoi diritti</h4>
                            <p>
                                Qualsiasi persona fisica che utilizzi il nostro servizio può:
                                <li>ottenere dal titolare, in ogni momento, informazioni circa l’esistenza dei propri dati personali, l’origine degli stessi, le finalità e le modalità di trattamento e, qualora presenti, di ottenere l’accesso ai dati personali ed alle informazioni di cui all’articolo 15 del GDPR</li>
                                <li>richiedere l’aggiornamento, la rettifica, l’integrazione, la cancellazione, la limitazione del trattamento dei dati nel caso ricorra una delle condizioni previste all’articolo 18 del GDPR, la trasformazione in forma anonima o il blocco dei dati personali, trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti e/o successivamente trattati</li>
                                <li>opporsi, in tutto o in parte, per motivi legittimi e secondo quanto previsto dalla legge, al trattamento dei dati, ancorché pertinenti allo scopo della raccolta ed al trattamento dei dati personali previsti ai fini di informazione commerciale o di invio di materiale pubblicitario o di vendita diretta ovvero per il compimento di ricerche di mercato o di comunicazione commerciale. Ogni utente ha altresì il diritto di revocare il consenso in qualsiasi momento senza pregiudicare le liceità del trattamento basata sul consenso prestato prima della revoca</li>
                                <li>ricevere i propri dati personali, trattati sulla base di un contratto o sul consenso dell’interessato, forniti consapevolmente ed attivamente o attraverso la fruizione del servizio, in un formato strutturato, di uso comune e leggibile da dispositivo automatico, e di trasmetterli ad un altro titolare del trattamento senza impedimenti</li>
                                <li>proporre reclamo presso l’Autorità Garante per la protezione dei dati personali in Italia</li>

                                Ti ricordiamo che per qualsiasi domanda o richiesta relativa ai tuoi dati personali e al rispetto della tua privacy puoi scrivere all’indirizzo info@tattooswap.it.
                            </p>
                        </li>
                        <li>
                            <h3>6. Come e per quanto tempo i tuoi dati saranno conservati?</h3>
                            <p>
                                La conservazione dei dati personali avverrà in forma cartacea e/o elettronica/informatica e per il tempo strettamente necessario al soddisfacimento delle finalità di cui al punto 2, nel rispetto della tua privacy e delle normative vigenti.
                            </p>
                            <p>
                                Per una maggiore sicurezza del servizio mediante attività di moderazione e prevenzione di condotte contrarie alle condizioni e alle regole editoriali di TattooSwap – anche in collaborazione con le Autorità – gli annunci, i contenuti generati dall’utente sulla piattaforma e quelli relativi alla sua identificazione (ad eccezione delle richieste di assistenza nei confronti del Servizio Clienti, la cui conservazione segue il ciclo di vita dell’utente) saranno in ogni caso conservati dal titolare, in forma protetta e con accesso limitato. Tali contenuti riservati saranno ad esclusivo uso interno e potranno essere forniti dal titolare solo all’Autorità Giudiziaria o al difensore dell’utente per finalità di giustizia.
                            </p>
                            <p>
                                Le fatture, i documenti contabili e i dati relativi alle transazioni sono conservati per 11 anni ai sensi di legge (ivi compresi gli obblighi fiscali).
                            </p>
                            <p>
                                Nel caso di esercizio del diritto all’oblio attraverso richiesta di cancellazione espressa dei dati personali trattati dal titolare, ti ricordiamo che tali dati saranno conservati, in forma protetta e con accesso limitato, unicamente per finalità di accertamento e repressione dei reati, per un periodo non superiore ai 12 mesi dalla data della richiesta (salvo quanto sopra indicato in merito ai dati utilizzati ai fini di moderazione e prevenzione) e successivamente saranno cancellati in maniera sicura o anonimizzati in maniera irreversibile.
                            </p>
                        </li>
                        <li>
                            <h3>7. Come assicuriamo la protezione dei tuoi dati?</h3>
                            <p>
                                I dati sono raccolti dai soggetti indicati al punto 4, secondo le indicazioni della normativa di riferimento, con particolare riguardo alle misure di sicurezza previste dal GDPR (art. 32) per il loro trattamento mediante strumenti informatici, manuali ed automatizzati e con logiche strettamente correlate alle finalità indicate al punto 2 e comunque in modo da garantire la sicurezza e la riservatezza dei dati stessi.
                            </p>
                            <p>
                                Nel rispetto della normativa applicabile, è attivo un sistema di verifica antispam sulle comunicazioni tra utenti. I dati ivi inseriti potranno essere verificati al solo scopo di individuare attività illecite o contenuti non conformi alle Condizioni generali del Servizio, ma non saranno trattati o comunicati per finalità commerciali o promozionali.
                            </p>
                        </li>
                        <li>
                            <h3>8. Ulteriori informazioni</h3>
                            <h4>8.1. Messaggi Diretti</h4>
                            <p>
                                La funzione Messaggi Diretti è volta ad una comunicazione immediata fra utenti dei servizi di TattooSwap che interagiscono tra loro esclusivamente per la compravendita dei propri beni o servizi.
                            </p>
                            <p>
                                Potremmo verificare il contenuto dei Messaggi al fine di moderare gli stessi per finalità di sicurezza e per preservare la netiquette e le regole editoriali di TattooSwap.
                            </p>
                            <p>
                                L’utilizzo del servizio Messaggi Diretti comporta la possibilità che l’identità dell’utente (così come indicata all’atto della registrazione/erogazione dei servizi di TattooSwap) e relativi contenuti siano resi noti durante le sessioni di attività.
                            </p>
                            <p>
                                Ci riserviamo il diritto di escludere dal servizio Messaggi Diretti, e più in generale dai servizi offerti da tattooSwap, tutti coloro che non osservino le regole e/o non rispettino le finalità definite per l’utilizzo del servizio Messaggi Diretti, nonché tutti coloro che adottino comportamenti non corretti e/o non rispettosi delle persone.
                            </p>
                            <h4>8.2. Sondaggi</h4>
                            <p>
                                Potremmo chiederti, durante la navigazione, la disponibilità a partecipare a sondaggi mirati allo scopo di conoscere il tuo parere ed il grado di soddisfazione relativamente ai servizi forniti. Tali sondaggi potranno essere svolti direttamente su TattooSwap.
                            </p>
                            <p>
                                All’interno dei sondaggi potrà essere richiesto, in maniera facoltativa, di fornire un indirizzo e-mail al fine di ricevere notizie ed informazioni sui servizi di TattooSwap.
                            </p>
                            <p>
                                Ti ricordiamo che la partecipazione ai sondaggi è del tutto facoltativa e che puoi sempre opporti a tale trattamento.
                            </p>
                            <h4>8.3. Posizione</h4>
                            <p>
                                Quando utilizzi le applicazioni e i servizi di TattooSwap con il rilevamento della posizione attivo, potremmo raccogliere ed elaborare informazioni sulla tua posizione attuale (approssimativa). Questi dati vengono trattati in forma anonima, in un formato che non consente di identificare personalmente l’utente ed utilizzati al solo fine di facilitare la fruizione di alcune funzionalità del servizio basate sulla posizione. Puoi attivare/disattivare in qualsiasi momento i servizi di localizzazione accedendo alle impostazioni del tuo browser e/o del tuo dispositivo come segue:
                            </p>
                            <h4>Safari</h4>
                            <p>
                            Desktop: vai su “Preferenze” > “Siti web” > “Posizione” e rimuovi TattooSwap dall’elenco                            </p>
                            <p>
                            Mobile: vai su “Preferenze” >”Privacy” > “Localizzazione” e disattiva la localizzazione per Safari
                            </p>
                            <h4>Chrome</h4>
                            <p>
                                Desktop: apri Chrome chrome://settings/content/location e rimuovi TattooSwap dall’elenco
                            </p>
                            <p>
                            Mobile: apri Chrome e seleziona “Impostazioni sito” > “Posizione” e rimuovi TattooSwap dall’elenco
                            </p>
                            <h4>Firefox</h4>
                            <p>
                            Desktop e mobile: apri Firefox e vai all’indirizzo about:preferences#privacy, cerca “Permission” > “Location” > “Settings” e rimuovi TattooSwap dall’elenco
                            </p>
                            <h4>Internet Explorer</h4>
                            <p>
                                Desktop: apri Internet Explorer, clicca sull’icona “ingranaggio” e seleziona “Opzioni internet” nel pannello privacy, alla voce Posizione premi il pulsante “Cancella Siti”
                            </p>
                            <br />
                            <h4>8.4. Motori di ricerca</h4>
                            <p>
                                Le informazioni relative agli annunci inseriti su TattooSwap saranno visibili nelle ricerche effettuate nel motore di ricerca interno e potrebbero essere messe a disposizione di motori di ricerca terzi in quanto è consentita l’indicizzazione dei contenuti da parte di motori terzi. Nel caso in cui la pagina relativa all’annuncio sia già stata rimossa da TattooSwap, è possibile che la copia cache permanga tra i risultati di ricerca per qualche giorno. I risultati della ricerca non sono gestiti da TattooSwap, ma l’utente può segnalare la rimozione della pagina e sollecitare l’aggiornamento della copia cache direttamente al motore di ricerca di terzi.
                            </p>
                        </li>
                        <li>
                            <h3>9. L’informativa sulla privacy può subire modifiche nel tempo?</h3>
                            <p>
                                La presente informativa potrebbe essere soggetta a modifiche. Qualora vengano apportate sostanziali modifiche all’utilizzo dei dati relativi all’utente da parte del Titolare, quest’ultimo avviserà l’utente pubblicandole con la massima evidenza sulle proprie pagine o tramite mezzi alternativi o similari.
                            </p>
                        </li>
                        <p>
                            Ultimo aggiornamento: 25/10/2024
                        </p>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage;