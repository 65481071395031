import { createSlice } from "@reduxjs/toolkit";

const userOriginSlice = createSlice({
    name: 'userOrigin',
    initialState: {
        from: null
    },
    reducers: {
        setUserOrigin: (state, action) => {
            state.from = action.payload
        }
    }
})

export const { setUserOrigin } = userOriginSlice.actions;
export default userOriginSlice.reducer;