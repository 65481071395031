import { Link, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FetchLoader from '../components/FetchLoader.jsx';
import UserAvatar from "../components/UserAvatar.jsx";
import CategoryTag from '../components/CategoryTag.jsx';
import { GoHomeButton, PrimaryButton, SecondaryButton, TealButton } from "../components/Buttons.jsx";
import { useDispatch, useSelector } from "react-redux";
import SlidePhotoGallery from "../components/SlidePhotoGallery.jsx";
import { setIsNeedRefresh } from "../redux/homepageSlice.js";
import DynamicHelmet from "../components/DynamicHelmet.jsx";

const ProductPage = ({ }) => {
    const { productId } = useParams();
    // Dati prodotto
    const [productData, setProductData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('productData: ', productData)
    }, [productData])

    const { id, isLogged } = useSelector(state => state.login);

    // Imposto la condizione se il supplier e l'utente sono la stessa persona
    // non permettendo in quel caso né l'acquisto né l'invio di messaggi
    const [isUserSupplier, setIsUserSupplier] = useState(false);
    useEffect(() => {
        if (productData && id) {
            if (productData.supplier === id) {
                setIsUserSupplier(true)
            }
        }
    }, [productData, id])

    // FETCH FOR PRODUCT DATA
    const getProductDetails = async () => {
        try {
            let headers = {
                'Content-Type': 'application/json'
            };
            if (isLogged) {
                const token = localStorage.getItem('tattooswap_auth_token');
                headers['Authorization'] = `Bearer ${token}`;
            }
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/${isLogged ? 'get-product' : 'get-shared-product'}`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ productId })
            })
            const productObject = await response.json();
            if (response.ok) {
                setProductData(productObject.payload)
            } else {
                console.log('object error: ', productObject)
                if (productObject.message === "Il token fornito non è valido o è scaduto") {
                    navigate("/login");
                }
            }
        } catch (error) {
            console.error('Catch error: ', error)
        }
    }

    useEffect(() => {
        getProductDetails()
    }, [])

    //Debug
    useEffect(() => {
        console.log('productData: ', productData)
    }, [productData])

    // Fetch per nome e cognome del supplier
    const [supplier, setSupplier] = useState({
        _id: null,
        name: null,
        surname: null,
        avatarUrl: null
    })
    const sendSupplierFetch = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/users/get-user-names/${productData.supplier}`;
        const response = await fetch(url, { method: 'GET', headers: { Authorization: `Bearer ${token}` } });
        if (response.ok) {
            const result = await response.json();
            setSupplier({
                _id: result.payload._id,
                name: result.payload.name,
                surname: result.payload.surname,
                avatarUrl: result.payload.avatarUrl
            })
        }
    }
    useEffect(() => {
        if (productData) {
            sendSupplierFetch();
        }
    }, [productData])

    // Catturo la query con la distanza se c'è e il backlink se c'è
    const [searchParams] = useSearchParams();
    const distance = searchParams.get('distance');
    const backlink = searchParams.get('backlink')
    useEffect(() => {
        // Inserire azioni nel caso la query 'distance' nell'url contiene un valore
    }, [distance])

    // Handle buying
    const [isBuying, setIsBuying] = useState(false);
    const [fetchStatus, setFetchStatus] = useState('idle');
    const reduxPickerId = useSelector((state) => state.login.id)
    const token = localStorage.getItem('tattooswap_auth_token');
    const sendRequest = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/send-request?productId=${productData._id}&pickerId=${reduxPickerId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                setFetchStatus('succeeded');
            } else {
                const errorData = await response.json()
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error')
            }
        } catch (error) {
            console.error('Catch error: ', error)
            setFetchStatus('error')
        }
    }

    // Setto isNeedRefresh per l'homepage a richiesta inviata (perché il prodotto va' tolto da quelli visualizzati in home)
    const { isNeedRefresh } = useSelector(state => state.homepage)
    const dispatch = useDispatch();
    useEffect(() => {
        if (fetchStatus === 'succeeded') {
            dispatch(setIsNeedRefresh(true))
        };
        if (fetchStatus === 'error') {
            setTimeout(() => {
                setFetchStatus('idle')
            }, 3000);
        }
    }, [fetchStatus])

    // Handle share link
    const [linkCopied, setLinkCopied] = useState(false)
    const handleShareLink = () => {
        const link = `${process.env.REACT_APP_PUBLIC_URL}/shared-product/${productId}`;
        // Crea un elemento textarea nascosto
        const textarea = document.createElement('textarea');
        textarea.value = link;
        document.body.appendChild(textarea);
        // Seleziona e copia il testo nell'elemento textarea
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setLinkCopied(true);
    }
    useEffect(() => {
        if (linkCopied) {
            setTimeout(() => {
                setLinkCopied(false)
            }, 3000)
        }
    }, [linkCopied])

    // Fromatted create date
    const [formattedDate, setFormattedDate] = useState('');
    const formatDate = () => {
        const date = new Date(productData.createdAt);
        const formattedDate = date.toLocaleString();
        setFormattedDate(formattedDate);
    }
    useEffect(() => {
        if (productData) {
            formatDate();
        }
    }, [productData])

    // Handle send message to supplier
    const [isInputOpen, setIsInputOpen] = useState(false);
    const openInput = () => {
        setIsInputOpen(true)
    }
    const closeInput = () => {
        setIsInputOpen(false)
    }
    const userId = useSelector(state => state.login.id);
    const [messageData, setMessageData] = useState({
        applicantId: null,
        supplierId: null,
        productId: null,
        message: ''
    });
    useEffect(() => {
        if (productData) {
            setMessageData(prevData => ({
                ...prevData,
                supplierId: productData.supplier,
                productId: productData._id
            }))
        }
    }, [productData])
    useEffect(() => {
        if (userId) {
            setMessageData(prevData => ({
                ...prevData,
                applicantId: userId
            }))
        }
    }, [userId])
    const handleInputMessage = (event) => {
        const { value } = event.target
        setMessageData(prevData => ({
            ...prevData,
            message: value
        }))
    }
    useEffect(() => {
        console.log('messageData: ', messageData)
    }, [messageData])
    const [messageFetchStatus, setMessageFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const sendMessage = async () => {
        try {
            setMessageFetchStatus('loading');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/open-new-conversation`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(messageData)
            });
            if (response.ok) {
                setMessageFetchStatus('succeeded');
                window.location.href = "/inbox";
            } else {
                const errorResult = await response.json();
                setError(errorResult)
                setMessageFetchStatus('failed');
            }
        } catch (error) {
            setError(error);
            setMessageFetchStatus('failed');
        }
    }
    useEffect(() => {
        console.log(error)
    }, [error])

    // Gestisco il click sulle foto della slide
    const [imageShowed, setImageShowed] = useState(null);
    useEffect(() => {
        if (productData) setImageShowed(productData.coverImage.secure_url)
    }, [productData])
    const showImage = (url) => {
        setImageShowed(url);
    }

    return (
        <div className="main-container">
            {
                productData &&
                <DynamicHelmet
                    title={productData.title}
                    description={productData.description}
                    keywords={productData.tags}
                    imageUrl={productData.coverImage.resizedUrl}
                    url={`https://www.tattooswap.it/product-page/${productData._id}`}
                />
            }
            {
                !isLogged &&
                <Link to={!backlink || "/"}><div className="flex justify-center items-center border-2 rounded-lg p-4 cursor-pointer bg-white">
                    <i class="fi fi-rr-arrow-small-left text-3xl mb-[-8px]"></i>
                    <h4>Vai alla home</h4>
                </div></Link>
            }
            {
                !isLogged &&
                <Link to="/login"><TealButton text="Esegui il login" /></Link>
            }
            {isLogged && <GoHomeButton />}
            <div className="box">
                {
                    !productData ? (
                        <FetchLoader />
                    ) : (
                        <div className="w-full flex flex-col gap-2">
                            {/* Title Section */}
                            <div className="flex justify-between">
                                <div className="flex gap-2 items-center">
                                    <UserAvatar url={supplier.avatarUrl} />
                                    <div className="flex flex-col items-start">
                                        <div className="text-xs mb-[-4px]">Messo in vendita da:</div>
                                        <h3>{supplier.name} {supplier.surname}</h3>
                                    </div>
                                </div>

                                <div onClick={handleShareLink} className="relative">
                                    {!linkCopied && <i className="fi fi-rr-share text-xl md:text-3xl cursor-pointer"></i>}
                                    {linkCopied && <div className="absolute right-0 w-[200px] bg-teal-500 bg-opacity-80 border-2 rounded-lg p-1">
                                        <p className="text-sm mt-1">Link copiato.</p>
                                        <h3 className="text-white text-sm mt-[-10px]">Condividilo con chi vuoi!</h3>
                                    </div>}
                                </div>
                            </div>

                            {/* <h4 className="self-start">ID prodotto: {productData._id}</h4> */}

                            {/* PHOTO */}
                            <div className="my-2">
                                {imageShowed && <Link to={imageShowed} ><div className="w-[360px] md:w-[540px] h-[360px] md:h-[540px] overflow-hidden"><img src={imageShowed} alt="product" className="rounded-3xl w-full h-full object-cover cursor-pointer" /></div></Link>}
                                {!imageShowed && <FetchLoader />}
                            </div>
                            <SlidePhotoGallery photoArray={productData.gallery} showImage={showImage} />

                            {/* Details Section */}
                            <div className="flex flex-col items-start text-left">
                                <h2>{productData.price.toFixed(2)}€</h2>
                                <h3>{productData.title}</h3>
                                <CategoryTag cat={productData.category} />
                                {formattedDate && <p>Oggetto inserito il: {formattedDate}</p>}
                                <p>Condizioni oggetto: <strong>{productData.wornStatus}</strong></p>
                                <hr className="text-black h-[1px] w-full ml-0" />
                                <p>{productData.description}</p>
                                <hr className="text-black h-[1px] w-full ml-0" />

                            </div>

                            {/* User Action */}
                            {
                                !isUserSupplier && isLogged && !isBuying ? (
                                    <div className="flex flex-col gap-2">
                                        <TealButton text="Acquista questo oggetto" click={() => setIsBuying(true)} />
                                        <p>oppure</p>
                                        {!isInputOpen && <SecondaryButton text="Fa' una domanda al proprietario dell'oggetto" click={openInput} />}
                                        {
                                            isInputOpen &&
                                            <div className="flex flex-col gap-2">
                                                <input type="text" onChange={handleInputMessage} />
                                                <div className="w-full flex justify-center gap-2">
                                                    {messageFetchStatus === 'idle' && <SecondaryButton text="Annulla" click={closeInput} />}
                                                    {messageFetchStatus === 'idle' && <TealButton text="Invia" click={sendMessage} />}
                                                    {messageFetchStatus === 'loading' && <FetchLoader />}
                                                    {
                                                        messageFetchStatus === 'succeeded' &&
                                                        <div>
                                                            <h3>Messaggio inviato.</h3>
                                                            <i className="fi fi-br-check text-teal-400 text-3xl"></i>
                                                        </div>
                                                    }
                                                    {
                                                        messageFetchStatus === 'failed' &&
                                                        <div>
                                                            <h3>Qualcosa è andato storto, ricarica la pagina e riprova</h3>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ) : (
                                    !isUserSupplier && isLogged && <div className="bg-teal-100 p-4 border-2 border-teal-500 rounded flex flex-col items-center">
                                        <div className="text-center">
                                            <h4>Confermando invierai all'utente proprietario una richiesta per l'acquisto di quest'oggetto.</h4>
                                            <p>Se il proprietario dovesse accettare potrà utilizzare il tuo numero di telefono o la tua email per contattarti in modo che vi accordiate in privato per lo scambio e il pagamento.</p>
                                        </div>
                                        {
                                            fetchStatus === 'pending' ? (
                                                <FetchLoader />
                                            ) : (
                                                fetchStatus === 'idle' ? (
                                                    <div className="flex gap-2">
                                                        <TealButton text="Conferma la richiesta" click={sendRequest} />
                                                        <SecondaryButton text="Annulla" click={() => setIsBuying(false)} />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {fetchStatus === 'succeeded' &&
                                                            <>
                                                                <h2>Richiesta di acquisto inviata</h2>
                                                                <p>Attendi che il proprietario dell'oggetto ti contatti per telefono o via email.</p>
                                                            </>
                                                        }
                                                        {
                                                            fetchStatus === 'error' &&
                                                            <>
                                                                <h3>Qualcosa è andato storto. Ricarica la pagina e riprova</h3>
                                                            </>
                                                        }
                                                    </>
                                                )
                                            )
                                        }
                                    </div>
                                )
                            }
                            {!isLogged && <Link to="/login"><TealButton text="Esegui il login" /></Link>}
                            {isUserSupplier && <h2 className="text-teal-500 mt-8 text-start">Sei il proprietario di questo oggetto.</h2>}
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default ProductPage;