import { useEffect, useRef, useState } from "react";
import { motion } from 'framer-motion';
import uploadIcon from '../images/upload.png';
import { TealButton } from './Buttons';
import FetchLoader from "./FetchLoader";
import ReorderModal from "./ReorderModal";

const UploadPhotos = ({ inputDataGallery, setInputData, coverPublicId, inputDataCover }) => {
    // Gestisco l'upload delle immagini
    const fileInputRef = useRef();
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isImageLoading, setIsImageLoading] = useState(false);
    const [isSizeError, setIsSizeError] = useState(false);
    useEffect(() => {
        console.log('isSizeError: ', isSizeError)
    }, [isSizeError])

    const handleUploadImage = (event) => {
        event.preventDefault();
        setIsImageLoading(true);
        const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        if (file) {
            // Controllo che non ecceda il size dell'immagine
            const fileSize = file.size / (1024 * 1024); // in Mb
            console.log('fileSize: ', fileSize);
            if (fileSize > 9) {
                console.log('too big');
                setIsSizeError(true);
                setIsImageLoading(false);
                setTimeout(() => {
                    inputEmpty();
                    setIsSizeError(false);
                }, 3000)
            } else {
                console.log('or not too big');
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    setUploadedImage(reader.result);
                    setIsImageLoading(false);
                }
            }
        }
    }

    // Gestisco l'eliminazione dell'immagine dall'input
    const handleDeleteImageInput = () => {
        setUploadedImage(null);
        inputEmpty();
    }
    // Funzione che svuota l'input di Upload
    const inputEmpty = () => {
        let inputElement = document.getElementById('fileInput');
        inputElement.value = '';
    }

    // Gestisco l'overlay dell'immagine inviata con successo
    const [isOverlay, setIsOverlay] = useState(false);
    const handleOverlay = () => {
        setIsOverlay(true);
        setTimeout(() => {
            setIsOverlay(false)
        }, 3000);
    }

    // Gestisco l'aggiornamento della coverImage qualora fosse il primo caricamento di immagine
    useEffect(() => {
        if (inputDataGallery.length === 1) {
            setInputData(prevState => ({
                ...prevState,
                coverImage: prevState.gallery[0]
            }))
        }
    }, [inputDataGallery])

    useEffect(() => {
        console.log('inputDataGallery: ', inputDataGallery)
    }, [inputDataGallery])

    // Invio dell'immagine droppata al server
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [fetchError, setFetchError] = useState(null);
    const sendImage = async () => {
        try {
            setFetchStatus('loading');
            const token = localStorage.getItem('tattooswap_auth_token');
            const url = `${process.env.REACT_APP_BASE_URL}/products/upload-image`;
            const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
            const options = { method: 'POST', headers, body: JSON.stringify({ image: uploadedImage }) };
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                setUploadedImage(null);
                setFetchStatus('succeeded');
                console.log('result: ', result)
                setInputData(prevState => ({
                    ...prevState,
                    gallery: [...prevState.gallery, result.payload],
                }));
                handleOverlay();
            } else {
                const error = await response.json();
                setFetchError('Fetch error: ', error.message);
                setFetchStatus('failed');
            }
        } catch (error) {
            setFetchError(error.message)
            setFetchStatus('failed');
        }
    }

    // Gestisco l'eliminazione di un immagine (già caricata sul server e quindi in gallery)
    // Se l'immagine era di copertina, la coverImage diventa automaticamente la prima dell'array
    const [deleteFetchStatus, setDeleteFetchStatus] = useState('idle');
    const handleDeleteImage = async (event) => {
        const { id } = event.target;
        setDeleteFetchStatus('loading');

        // mando al server l'id dell'immagine da eliminare
        const photoPublicId = inputDataGallery.find(element => element.public_id === id).public_id;
        console.log('photoPublicId: ', photoPublicId);

        try {
            const token = localStorage.getItem('tattooswap_auth_token');
            const url = `${process.env.REACT_APP_BASE_URL}/products/delete-image`;
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            };
            const options = {
                method: 'DELETE',
                headers,
                body: JSON.stringify({ public_id: photoPublicId })
            }
            const response = await fetch(url, options);
            if (response.ok) {
                const result = response.json();
                console.log('delete result: ', result)

                // cancello l'immagine da inputData.gallery
                let oldGallery = [...inputDataGallery];
                console.log('oldGallery: ', oldGallery);
                let newGallery = oldGallery.filter(element => element.public_id !== id);
                console.log('newGallery: ', newGallery);
                setInputData(prevState => ({
                    ...prevState,
                    gallery: newGallery
                }))
                // Elimino la copertina se corrisponde alla foto eliminata
                if (photoPublicId === coverPublicId) {
                    setInputData(prevState => ({
                        ...prevState,
                        coverImageUrl: null
                    }))
                }
                //
                setDeleteFetchStatus('succeeded');
            } else {
                const error = response.json();
                console.log('delete fetch error: ', error)
                setDeleteFetchStatus('failed');
            }
        } catch (error) {
            console.log('delete catch error: ', error)
            setDeleteFetchStatus('failed');
        }
    }

    // Gestisco la selezione della coverImage
    const handleSelectCover = (index) => {
        let newCoverImage = { ...inputDataGallery[index] };
        if ((!inputDataCover) || inputDataCover && inputDataCover.public_id !== newCoverImage.public_id) {
            setInputData(prevState => ({
                ...prevState,
                coverImage: newCoverImage
            }))
        }
    }

    return (
        <div className="my-8 flex flex-col border-2 border-slate-200 rounded p-4 w-full">

            {/* Anteprima foto (dal server) */}
            <div className="flex flex-col gap-2 bg-white p-2 rounded-xl">
                {inputDataGallery && inputDataGallery.length === 0 && <h3 className="text-neutral-400 italic">Nessun immagine caricata</h3>}
                {inputDataGallery && inputDataGallery.length > 0 && <h3>Foto caricate:</h3>}
                <div className='relative'>
                    {deleteFetchStatus === 'loading' && <div className="absolute top-0 right-0 bottom-0 left-0 bg-white opacity-80 z-10 flex justify-center items-center"><FetchLoader /></div>}
                    {
                        inputDataGallery && inputDataGallery.length > 0 &&
                        <ReorderModal inputDataGallery={inputDataGallery} setInputData={setInputData} coverPublicId={coverPublicId} handleSelectCover={handleSelectCover} handleDeleteImage={handleDeleteImage} />
                    }
                </div>
                <h5 className="italic text-teal-500">(Clicca su una foto per impostarla come copertina)</h5>
                <h5 className="italic text-xs">(Trascina i blocchi per riordinare le foto della galleria)</h5>
            </div>


            {/* Uploader */}
            <motion.div
                onDragOver={(e) => e.preventDefault()}
                whileOver={!uploadedImage && { backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
                onDrop={handleUploadImage}
                dragContraints={{ top: 0, left: 0, bottom: 0, right: 0 }}
            >
                <div className="flex flex-col items-center justify-center w-full border-[4px] border-dashed border-teal-400 rounded-xl p-8 text-center my-8 min-h-[358px] relative">
                    {/* Input nascosto */}
                    <input ref={fileInputRef} type="file" accept="image/" id="fileInput" onChange={handleUploadImage} style={{ display: 'none' }} />

                    {/* Succeeded overlay */}
                    {
                        isOverlay &&
                        <div className="absolute top-0 right-0 bottom-0 left-0 bg-neutral-200 rounded-lg opacity-90 flex flex-col items-center justify-center gap-2">
                            <i className="fi fi-br-check text-6xl text-teal-400"></i>
                            <h3>Foto caricata!</h3>
                        </div>
                    }
                    {
                        /* Anteprima */
                        uploadedImage && (fetchStatus === 'idle' || fetchStatus === 'succeeded') &&
                        <div className="p-4 rounded-xl flex flex-col items-center lg:flex-rows gap-4 bg-slate-100">
                            <div className="self-end"><i className="fi fi-sr-circle-xmark text-3xl text-slate-500 cursor-pointer" onClick={handleDeleteImageInput}></i></div>
                            <h3>Vuoi caricare questa foto?</h3>
                            <div className="w-24 h-24 overflow-hidden rounded-lg"><img src={uploadedImage} className="w-full h-full object-cover" /></div>
                            {!isSizeError && <TealButton text="Carica immagine" click={sendImage} />}
                        </div>
                    }
                    {
                        uploadedImage && fetchStatus === 'loading' &&
                        <FetchLoader />
                    }
                    {
                        !uploadedImage && (fetchStatus === 'idle' || fetchStatus === 'succeeded') &&
                        <div className="flex flex-col gap-2 items-center">
                            <img src={uploadIcon} alt="upload" className="w-32" />
                            <h2 className="text-neutral-500">Drag & Drop</h2>
                            <p>oppure</p>
                            {!isImageLoading && !isSizeError && <div className="my-8"><TealButton text="Seleziona file" icon={<i className="fi fi-br-cloud-upload text-2xl"></i>} click={() => fileInputRef.current.click()} /></div>}
                            {isSizeError && <h4 className="text-red-500">L'immagine supera le dimensioni consentite</h4>}
                            <p>
                                La dimensione massima consentita per i file è di 10 MB.
                                Sono consentiti files di tipo 'jpg' e 'png'
                            </p>
                        </div>

                    }
                </div>
            </motion.div>
        </div>
    )
}

export default UploadPhotos