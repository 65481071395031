import ProductCard from "../ProductCard.jsx";
import { useNavigate } from "react-router-dom";

const BaseProductsPanel = ({ title, array }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/all-products")
    }
    return (
        <div className="flex flex-col items-center">
            <h2 className='pl-2 w-full text-left mb-4 text-teal-500 font-normal border-b border-teal-500'>{title}:</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-3 md:gap-6 2xl:gap-6">
                {
                    array && array.map((element, index) => {
                            return <ProductCard key={index} productData={element} />
                    })
                }
            </div>
            <button className="my-8 bg-teal-400 h-fit w-fit rounded-lg text-white py-3 px-4 cursor-pointer mr-12" onClick={handleClick}>Visualizza altri prodotti...</button>

        </div>
    )
}

export default BaseProductsPanel;