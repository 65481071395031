import { Helmet } from "react-helmet-async"

const DynamicHelmet = ({ title, description, keywords, imageUrl, url }) => {
    return (
        <Helmet>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            {/* Open Graph Meta Tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:type" content="website" />

            <link rel="canonical" href={url} />
        </Helmet>
    )
}

export default DynamicHelmet