import AdvBlock from "../components/AdvBlock";
import { GoHomeButton } from "../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { getAllConversations } from "../redux/conversationsSlice";
import { useEffect } from "react";
import ConversationCard from "../components/inboxComponents/ConversationCard.jsx";
import FetchLoader from "../components/FetchLoader.jsx";

const InboxPage = () => {
    const { id } = useSelector(state => state.login)
    const dispatch = useDispatch();
    useEffect(() => {
        if (id) {
            dispatch(getAllConversations({
                id: id,
                page: 1,
                pageSize: 8
            }))
        }
    }, [id]);
    const { fetchStatus } = useSelector(state => state.conversations)
    const conversationsArray = useSelector(state => state.conversations.data.payload)

    // get conversation Alert
    const { newConversationAlert } = useSelector(state => state.login)

    return (
        <div className="main-container">
            <GoHomeButton />
            <h2>Messaggi</h2>

            {fetchStatus === 'succeeded' && <div className="box md:shadow-2xl max-w-[600px] gap-4">
                {conversationsArray && conversationsArray.length !== 0 && conversationsArray.map((element, index) => {
                    let isMyProduct = false;
                    let alert = false;
                    if (id === element.supplierUser._id) { isMyProduct = true }
                    if (newConversationAlert.includes(element._id)) {alert = true}
                    return <ConversationCard key={index} index={index} data={element} isMyProduct={isMyProduct} alert={alert} />
                })}
            </div>}
            {fetchStatus === 'idle' || fetchStatus === 'loading' && <FetchLoader />}
        </div>
    )
}

export default InboxPage;