export const getCountryFunction = async (lat, lng) => {
    try {
        const res = await fetch(`https://api.maptiler.com/geocoding/${lng},${lat}.json?KEY=${process.env.REACT_APP_MAPTILER_KEY}`);

        if (!res.ok) {
            throw new Error('Failed to fetch data');
        }

        const cityData = await res.json();
        if (cityData.features[0].place_name_it) {
            return cityData.features[0].place_name_it;
        } else {
            return cityData.features[0].place_name;
        }

    } catch (error) {
        console.error('Error fetching city: ', error)
    }
}