import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getUsersMainData = createAsyncThunk(
    'users/getAllUsers',
    async () => {
        const token = localStorage.getItem('tattooswap_auth_token');
        const url = `${process.env.REACT_APP_BASE_URL}/admin/`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const usersData = await response.json();
            console.log(usersData);
            return usersData;
        } else {
            const errorResult = await response.json();
            throw errorResult;
        }
    }
)

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        isLoading: false,
        error: null,
        usersMainData: null,
        usersCount: null,
        registrationData: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsersMainData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getUsersMainData.fulfilled, (state, action) => {
                state.usersMainData = action.payload.payload.usersMainData; // Fallo arrivare paginato :)
                state.usersCount = action.payload.payload.usersCount;
                state.registrationData = action.payload.payload.registrationData;
                state.isLoading = false;
            })
            .addCase(getUsersMainData.rejected, (state, action) => {
                state.error = action.error;
                state.isLoading = false;
            })
    }
})

export default adminSlice.reducer;