import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DisabledButton, GoHomeButton, PrimaryButton, SecondaryButton, TealButton } from "../components/Buttons.jsx";
import FetchLoader from "../components/FetchLoader.jsx";

import macchinetteImage from '../images/icons/macchinette.png';
import attrezzaturaImage from '../images/icons/attrezzatura.png';
import arredamentoImage from '../images/icons/arredamento.png';
import altroImage from '../images/icons/altro.png';
import closeImage from '../images/icons/close.png';
import UploadPhotos from "../components/UploadPhotos.jsx";

const AddEditProduct = () => {

    // Imposto new o edit
    const [isEdit, setIsEdit] = useState(false);
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    useEffect(() => {
        if (id) {
            getProductData();
            setIsEdit(true);
        }
    }, [id])

    // Se è una edit fetcho i dati del prodotto
    const [productData, setProductData] = useState(null);
    const [isProductLoading, setIsProductLoading] = useState(false);
    const [productFetchError, setProductFetchError] = useState(null);
    const getProductData = async () => {
        setIsProductLoading(true);
        const token = localStorage.getItem('tattooswap_auth_token')
        const url = `${process.env.REACT_APP_BASE_URL}/products/get-product`;
        const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
        const options = { method: 'POST', headers, body: JSON.stringify({ productId: id }) };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                setProductData(result.payload);
                setIsProductLoading(true);
            } else {
                const error = await response.json();
                throw new Error(error.message || 'Errore sconosciuto');
            }
        } catch (error) {
            console.error(error);
            setProductFetchError(error);
            setIsProductLoading(true);
        }
    }

    useEffect(() => {
        console.log('productData: ', productData)
        if (productData) {
            setInputData(productData)
        }
    }, [productData])

    // GET POSITION DATA
    const positionData = useSelector(state => state.login.basePosition);

    useEffect(() => {
        console.log(positionData)
        if (positionData) {
            setInputData(prevInputData => ({
                ...prevInputData,
                position: { lat: positionData.lat, lng: positionData.lng, country: positionData.country }
            }))
        }
    }, [positionData])

    // HANDLE IMAGE INPUT
    const [uploadedImage, setUploadedImage] = useState('');
    const fileInputRef = useRef(null);

    const handleInputImage = () => {
        fileInputRef.current.click();
    }

    const handleUploadImage = (event) => {
        const file = event.target.files[0];
        transformFile(file);
    }

    const transformFile = (file) => {
        const reader = new FileReader();

        if (file) {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setUploadedImage(reader.result);
            }
        } else {
            setUploadedImage('');
        }
    }

    const removeImage = () => {
        setUploadedImage('');
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    }

    useEffect(() => {
        setInputData(prevInputData => ({
            ...prevInputData,
            photo: uploadedImage
        }))
    }, [uploadedImage])

    // HANDLE INPUT DATA
    const supplier = useSelector(state => state.login.id);
    const initialState = {
        title: null,
        price: null,
        supplier: supplier,
        category: null,
        description: null,
        photo: null,
        gallery: [],
        coverImage: null,
        wornStatus: 'Nuovo',
        saleStatus: { status: 'Available', pickerId: '' },
        position: { lat: null, lng: null, country: null },
        tags: []
    }

    useEffect(() => {
        setInputData(prevInputData => ({
            ...prevInputData,
            supplier: supplier
        }))
    }, [supplier]) // Questo useEffect risolve il problema dello useSelector che non aggiorna supplier in tempo per l'initialState

    const [inputData, setInputData] = useState(initialState);

    const updateInputData = (event) => {
        const { id, value } = event.target;
        setInputData(prevuserData => ({
            ...prevuserData,
            [id]: value
        }));
    }

    const updateCategory = (event) => {
        const id = 'category';
        const value = event.target.id;
        const customEvent = { target: { id, value } };
        updateInputData(customEvent);
    }

    // SEND PRODUCT FETCH
    const token = localStorage.getItem('tattooswap_auth_token');
    const [isFetchPending, setIsFetchPending] = useState(false);
    const [isFetchDone, setIsFetchDone] = useState(false);
    const [isError, setIsError] = useState(false);
    const sendData = async () => {
        try {
            setIsFetchPending(true);
            let url;
            let method;
            if (isEdit) {
                url = `${process.env.REACT_APP_BASE_URL}/products/edit-product?userId=${productData.supplier}&productId=${productData._id}`;
                method = 'PATCH';
            } else {
                url = `${process.env.REACT_APP_BASE_URL}/products/add-new`;
                method = 'POST';
            }
            const response = await fetch(url, {
                method,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputData)
            });

            if (response.ok) {
                const productData = await response.json();
                setIsFetchDone(true);
            } else {
                throw new Error("Errore durante l'invio dei dati. Riprova.");
                setIsError(true);
            }
        } catch (error) {
            console.error('Errore: ', error)
        } finally {
            setIsFetchPending(false);
        }
    }

    // SEND BUTTON AVAILABILITY HANDLE
    const [allFilledIn, setAllFilledIn] = useState(false);
    useEffect(() => {
        if (
            inputData.title !== '' &&
            inputData.price !== '' &&
            inputData.category !== '' &&
            inputData.description !== '' &&
            inputData.gallery.length > 0
        ) {
            setAllFilledIn(true)
        } else {
            setAllFilledIn(false)
        }
    }, [inputData])

    // FETCH DONE TIMEOUT AND REDIRECT TO HOME
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isFetchDone && !isError) {
            setTimeout(() => {
                window.location.href = "/";
            }, 5000)
        }
    }, [isFetchDone])

    // Handle add tags
    const [tagInput, setTagInput] = useState('');
    const handleTagInput = (event) => {
        const { value } = event.target;
        setTagInput(value)
    }
    useEffect(() => {
        console.log('tagInput: ', tagInput);
    }, [tagInput])
    const addTag = () => {
        setInputData(prevData => ({
            ...prevData,
            tags: [
                ...prevData.tags,
                tagInput
            ]
        }));
        setTagInput('');
    }
    const removeTag = (event) => {
        const { id } = event.target;
        setInputData(prevData => ({
            ...prevData,
            tags: prevData.tags.filter(element => element !== id)
        }));
    }

    // Debug
    useEffect(() => {
        console.log('inputData: ', inputData)
    }, [inputData])

    // TEMPORANEO
    useEffect(() => {
        if (inputData.coverImage) {
            setInputData(prevState => ({
                ...prevState,
                photo: inputData.coverImage
            }))
        }
    }, [inputData.coverImage])
    return (
        <div className="main-container">
            <GoHomeButton />
            <div className="box md:shadow-2xl">
                {
                    (!isFetchDone && !isError) ? (
                        <>
                            <h3 className="mb-4">Aggiungi un tuo prodotto</h3>
                            <label htmlFor="title">Cosa vuoi mettere in vendita?</label>

                            {/* Titolo */}
                            <input type="text" id="title" onChange={updateInputData} value={inputData.title} />

                            {/* Categoria */}
                            <label htmlFor="category">A quale categoria appartiene?</label>
                            <div className="my-2 flex w-full justify-around gap-3">
                                <div className="w-16 flex flex-col items-center gap-1">
                                    <div className={`border-2 rounded-lg w-16 h-16 flex flex-col items-center justify-center hover:bg-slate-100 ${inputData.category === 'Macchinette' ? 'border-slate-500' : ''}`}><img src={macchinetteImage} className="w-12 h-12 cursor-pointer" id="Macchinette" onClick={updateCategory} /></div>
                                    <div className='text-slate-500 text-xs'>Macchinette</div>
                                    <div className='text-teal-500 text-xs'>(Macchinette, alimentatori, batterie, pedali, ecc)</div>
                                </div>
                                <div className="w-16 flex flex-col items-center gap-1">
                                    <div className={`border-2 rounded-lg w-16 h-16 flex flex-col items-center justify-center hover:bg-slate-100 ${inputData.category === 'Attrezzatura' ? 'border-slate-500' : ''}`}><img src={attrezzaturaImage} className="w-12 h-12 cursor-pointer" id="Attrezzatura" onClick={updateCategory} /></div>
                                    <div className='text-slate-500 text-xs'>Attrezzatura</div>
                                    <div className='text-teal-500 text-xs'>(Cartucce, aghi, colori, prodotti per l'igiene, ecc)</div>
                                </div>
                                <div className="w-16 flex flex-col items-center gap-1">
                                    <div className={`border-2 rounded-lg w-16 h-16 flex flex-col items-center justify-center hover:bg-slate-100 ${inputData.category === 'Arredamento' ? 'border-slate-500' : ''}`}><img src={arredamentoImage} className="w-12 h-12 cursor-pointer" id="Arredamento" onClick={updateCategory} /></div>
                                    <div className='text-slate-500 text-xs'>Arredamento</div>
                                    <div className='text-teal-500 text-xs'>(Poltrone, lettini, vetrinette, soprammobili, ecc)</div>
                                </div>
                                <div className="w-16 flex flex-col items-center gap-1">
                                    <div className={`border-2 rounded-lg w-16 h-16 flex flex-col items-center justify-center hover:bg-slate-100 ${inputData.category === 'Altro' ? 'border-slate-500' : ''}`}><img src={altroImage} className="w-12 h-12 cursor-pointer" id="Altro" onClick={updateCategory} /></div>
                                    <div className='text-slate-500 text-xs'>Altro</div>
                                    <div className='text-teal-500 text-xs'>(Tavole, ipad, cancelleria, altro, ecc)</div>
                                </div>
                            </div>

                            {/* Descrizione */}
                            <label htmlFor="description">Inserisci una descrizione</label>
                            <textarea id="description" className="h-48" style={{ verticalAlign: 'top' }} onChange={updateInputData} value={inputData.description} />

                            {/* Foto */}
                            <UploadPhotos inputDataGallery={inputData.gallery} setInputData={setInputData} coverPublicId={inputData.coverImage ? inputData.coverImage.public_id : null} inputDataCover={inputData.coverImage} />

                            {/* <div className="flex flex-col border-2 border-slate-200 rounded p-4 w-full">
                                <label htmlFor="photo" className="mt-[0px]">Carica una foto</label>
                                <div className='text-slate-500 text-xs self-start mb-4'>(Potrai caricarne altre successivamente)</div>
                                <PrimaryButton text="Carica foto" click={handleInputImage} width="100%" />
                                <input
                                    type="file"
                                    accept="image/"
                                    id="photo"
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleUploadImage} />
                                <div className="flex flex-col">
                                    {
                                        uploadedImage ? (
                                            <>
                                                <span className='text-slate-500 text-xs self-start mt-4 mb-1'>Anteprima immagine</span>
                                                <div className="relative w-fit">
                                                    <img src={uploadedImage} alt="anteprima" className="w-[100px] rounded-lg z-1" />
                                                    <div className="w-4 h-4 rounded absolute top-2 right-[-24px] flex justify-center items-center"><img src={closeImage} alt="close" onClick={removeImage} className="cursor-pointer" /></div>
                                                </div>
                                            </>
                                        ) : (
                                            <span className='text-slate-500 text-xs self-start mt-4'>Nessuna immagine caricata</span>
                                        )
                                    }
                                </div>
                            </div> */}

                            {/* Condizioni */}
                            <label htmlFor="wornStatus">In che condizioni è?</label>
                            <select for="option" id="wornStatus" onChange={updateInputData} value={inputData.status}>
                                <option value="Nuovo">Nuovo</option>
                                <option value="In buono stato">In buono stato</option>
                                <option value="In stato accettabile">In stato accettabile</option>
                            </select>

                            {/* Prezzo */}
                            <label htmlFor="price">Stabilisci un prezzo</label>
                            <div className="w-full flex justify-start items-center gap-2">
                                <input type="number" id="price" step="0.01" onChange={updateInputData} className="self-start w-48" value={inputData.price} /><span className="text-2xl text-slate-700">€</span>
                            </div>

                            {/* Tags */}
                            <label htmlFor="tags">Inserisci tag (opzionale)</label>
                            <div className="w-full flex flex-col gap-2">
                                <div className="flex gap-2">
                                    <input type="text" id="tag" className="self-start w-48" onChange={handleTagInput} value={tagInput} />
                                    <PrimaryButton text="Aggiungi" click={addTag} />
                                </div>
                                <div className="w-full border p-2 text-left flex flex-wrap gap-2">
                                    {inputData && inputData.tags.map((element, index) => (
                                        <div key={index} className="bg-teal-100 p-2 rounded flex gap-2">
                                            {element}
                                            <img src={closeImage} alt="close button" className="w-6 h-6 cursor-pointer" id={element} onClick={removeTag} />
                                        </div>
                                    ))}
                                </div>
                                <div className="text-left text-sm text-slate-500">
                                    <p>Inserendo i <b>tags</b> per il prodotto avrai un grande vantaggio: <b>aumenterai le possibilità che il tuo prodotto venga trovato</b> da Google (o altri motori di ricerca) e dagli altri utenti di Tattooswap all'interno della piattaforma.</p>
                                </div>
                            </div>



                            <hr className='bg-slate-500 w-full h-[1px]' />
                            {
                                !isFetchPending ? (
                                    <>
                                        <div className='text-slate-500 text-xs self-start mb-4'>Quando hai compilato tutti i campi, clicca su invia</div>
                                        {
                                            !allFilledIn ? (
                                                <DisabledButton text="invia" />
                                            ) : (
                                                <TealButton text="Invia" click={sendData} />
                                            )
                                        }
                                    </>
                                ) : (
                                    <div className="mt-4">
                                        <FetchLoader />
                                    </div>
                                )
                            }
                        </>
                    ) : (
                        isFetchDone && !isError ? (
                            <>
                                {!isEdit && <h3 className='text-teal-400'>Prodotto inserito!</h3>}
                                {isEdit && <h3 className='text-teal-400'>Prodotto aggiornato!</h3>}
                                <p>Verrai indirizzato in homepage fra 5 secondi...</p>
                            </>
                        ) : (
                            isError &&
                            <>
                                <h4 className='mb-4'>Qualcosa è andato storto...</h4>
                                <SecondaryButton text="Ricarica la pagina" click={() => window.location.reload(true)} />
                            </>
                        )
                    )
                }
            </div>

            {/* Adv block */}
            {/* <div className="w-[400px] md:w-[600px] lg:w-[900px] h-48 border-2 border-teal-500 bg-teal-100 rounded-lg flex justify-center items-center">Adv</div> */}
        </div>
    )
}

export default AddEditProduct;
