import macchinetteIcon from '../images/icons/macchinette.png';
import attrezzaturaIcon from '../images/icons/attrezzatura.png';
import arredamentoIcon from '../images/icons/arredamento.png';
import altroIcon from '../images/icons/altro.png';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewFilters, setPage } from '../redux/filtersSlice';

const CategoryFilter = () => {
    const { filtersObject } = useSelector(state => state.filters);
    const dispatch = useDispatch();

    const handleSetCategory = (event) => {
        const { innerText } = event.target;
        console.log('innerText: ', innerText);
        console.log('filtersObject.category: ', filtersObject && filtersObject.category)
        dispatch(setPage(1));
        if (filtersObject && filtersObject.category && filtersObject.category === innerText) {
            dispatch(setNewFilters({ category: null }))
        } else {
            dispatch(setNewFilters({ category: innerText }))
        }
    }
    return (
        <div className="w-full flex justify-center gap-2 sm:gap-4 2xl:gap-8">
            <div
                className={`flex w-[90px] sm:w-[120px] lg:w-[200px] 2xl:w-[250px] flex-col lg:flex-row justify-center items-center gap-2 py-4 border-2 ${filtersObject && filtersObject.category && filtersObject.category === 'Macchinette' && 'border-teal-400'} rounded-xl cursor-pointer md:hover:bg-slate-200`}
                onClick={handleSetCategory}
            >
                <img src={macchinetteIcon} alt="macchinette icon" className='w-8 2xl:w-12' />
                <h2 className='text-xs sm:text-sm lg:text-lg 2xl:text-2xl'>Macchinette</h2>
            </div>
            <div
                className={`flex w-[90px] sm:w-[120px] lg:w-[200px] 2xl:w-[250px] flex-col lg:flex-row justify-center items-center gap-2 py-4 border-2 ${filtersObject && filtersObject.category && filtersObject.category === 'Attrezzatura' && 'border-teal-400'} rounded-xl cursor-pointer md:hover:bg-slate-200`}
                onClick={handleSetCategory}
            >
                <img src={attrezzaturaIcon} alt="attrezzatura icon" className='w-8 2xl:w-12' />
                <h2 className='text-xs sm:text-sm lg:text-lg 2xl:text-2xl'>Attrezzatura</h2>
            </div>
            <div
                className={`flex w-[90px] sm:w-[120px] lg:w-[200px] 2xl:w-[250px] flex-col lg:flex-row justify-center items-center gap-2 py-4 border-2 ${filtersObject && filtersObject.category && filtersObject.category === 'Arredamento' && 'border-teal-400'} rounded-xl cursor-pointer md:hover:bg-slate-200`}
                onClick={handleSetCategory}
            >
                <img src={arredamentoIcon} alt="arredamento icon" className='w-8 2xl:w-12' />
                <h2 className='text-xs sm:text-sm lg:text-lg 2xl:text-2xl'>Arredamento</h2>
            </div>
            <div
                className={`flex w-[90px] sm:w-[120px] lg:w-[200px] 2xl:w-[250px] flex-col lg:flex-row justify-center items-center gap-2 py-4 border-2 ${filtersObject && filtersObject.category && filtersObject.category === 'Altro' && 'border-teal-400'} rounded-xl cursor-pointer md:hover:bg-slate-200`}
                onClick={handleSetCategory}
            >
                <img src={altroIcon} alt="altro icon" className='w-8 2xl:w-12' />
                <h2 className='text-xs sm:text-sm lg:text-lg 2xl:text-2xl'>Altro</h2>
            </div>
        </div>
    )
}

export default CategoryFilter;