import { useEffect } from "react";
import ProductCard from "../ProductCard";

// dovrà ricevere come props i filtri
const PanelCardsContainer = ({ objectArray, isDistance, backlink }) => {
    return (
        <>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 gap-3 md:gap-6 2xl:gap-6">
                {
                    Array.isArray(objectArray) && objectArray.map((element, index) => (
                        <ProductCard key={index} productData={element} isDistance backlink={backlink} />
                    ))
                }
            </div>
        </>
    )
}

export default PanelCardsContainer;