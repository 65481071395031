import { MapContainer, Marker, TileLayer, useMapEvent, useMap, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from "react-redux";
import { osm } from "../maptiler/osmProvider";
import { useEffect, useState } from "react";
import { Icon } from "leaflet";
import userMarkerIcon from '../images/position.png'
import { getMapProducts } from "../redux/mapProductsSlice";
import productIcon from '../images/location_box.png';
import { Link } from "react-router-dom";

const Map = () => {
    // Imposto i valori di default della mappa
    const defaultPosition = {
        lat: 41.89660184031861,
        lng: 12.483243285598416
    }
    const [zoomLevel, setZoomLevel] = useState(6);
    const [userMarkerPosition, setUserMarkerPosition] = useState(defaultPosition);

    const markerIcon = new Icon({
        iconUrl: userMarkerIcon,
        iconSize: [32, 64]
    })

    const boxIcon = new Icon({
        iconUrl: productIcon,
        iconSize: [32, 32]
    })

    // <MapEvent /> è momentaneamente disabilitato
    // Gestisco il click sulla mappa creando un componente dedicato <MapEvent />
    const MapEvent = () => {
        const map = useMapEvent('click', (event) => {
            setUserMarkerPosition(event.latlng)
        })
    }

    // Ricevo i dati dei prodotti da Redux mapProducts
    const { allProducts  } = useSelector(state => state.mapProducts.data);

    // Modifico la posizione e lo zoom iniziali se è presente isPosition
    // Da integrare con flyTo
    const { isPosition, lat, lng } = useSelector(state => state.position);
    useEffect(() => {
        if (isPosition) {
            setUserMarkerPosition({ lat, lng });
            setZoomLevel(12);
        }
    }, [isPosition, lat, lng])

    // Imposto lo spostamento con flyTo al rilevamento delle coordinate dell'utente
    const MapController = ({ center, zoom }) => {
        const map = useMap();
        useEffect(() => {
            map.flyTo(center, zoom, {
                duration: 2
            })
        }, [map, center, zoom])
        return null;
    }

    return (
        <div className="w-full lg:w-[800px] border-2 border-teal-400 rounded-xl overflow-hidden">
            <MapContainer
                className="h-[380px] w-full"
                center={isPosition ? [lat, lng] : [defaultPosition.lat, defaultPosition.lng]}
                zoom={zoomLevel}
            >
                <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
                {/* <MapEvent /> */}

                {/* User marker */}
                {isPosition && <Marker position={userMarkerPosition} icon={markerIcon}></Marker>}

                {/* Product markers */}
                {
                    allProducts && allProducts && allProducts.map((element, index) => (
                        <Marker key={index} position={element.position} icon={boxIcon}>
                            <Popup>
                                <Link to={`/product-page/${element._id}?backlink=products-map`}>
                                    <div className="w-[100px] flex flex-col items-center">
                                        <div className="max-h-[65px] overflow-y-hidden">
                                            <h3>{element.title}</h3>
                                        </div>
                                        <div className="w-24 h-24 overflow-hidden">
                                            <img src={element.photo.resizedUrl} className="w-full h-full object-cover" />
                                        </div>
                                    </div>
                                </Link>

                            </Popup>
                        </Marker>
                    ))
                }
                <MapController center={userMarkerPosition} zoom={zoomLevel} />
            </MapContainer>
        </div>
    )
}

export default Map