import { useState, useRef } from "react";
import ProductCard from "../ProductCard.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNewFilters } from "../../redux/filtersSlice.js";

const SlideCardsContainer = ({ title, array, filter }) => {
    const scrollContainerRef = useRef();

    const scrollLeft = () => {
        scrollContainerRef.current.scrollTo({
            left: scrollContainerRef.current.scrollLeft - 220,
            behavior: "smooth"
        })
    };
    const scrollRight = () => {
        scrollContainerRef.current.scrollTo({
            left: scrollContainerRef.current.scrollLeft + 220,
            behavior: "smooth"
        })
    };

    // Gestisco il click su Visualizza tutto
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleExtend = () => {
        dispatch(setNewFilters({ category: filter}));
        navigate("/all-products")
    }


    return (
        <div className="w-full lg:w-[800px] xl:w-[1000px] 2xl:w-[1200px]" id={title}>

            <h2 className='ml-6 lg:ml-0 pl-2 text-left text-teal-500 font-normal border-b border-teal-500'>{title}:</h2>

            <div className="flex gap-2 relative">
                {/* Desktop left arrow */}
                <div className="z-10 absolute left-2 lg:left-[-70px] top-20 w-12 h-12 mt-6 px-2 bg-slate-200 rounded-full hidden md:flex items-center justify-center pt-1 cursor-pointer" onClick={scrollLeft}><i class="fi fi-br-angle-left text-slate-500"></i></div>

                {/* Cards */}
                <div className='ml-2 md:ml-0 w-full gap-4 flex justify-start items-center flex-nowrap overflow-x-auto overflow-y-hidden pb-4 pt-2' ref={scrollContainerRef}>
                    <div className='text-teal-500 text-3xl flex md:hidden gap-2 mr-[-20px]'>
                        <i class="fi fi-rr-cursor-finger mt-[5px]"></i>
                        <div className='text-sm'><i class="fi fi-rr-arrow-left ml-[-25px]"></i></div>
                    </div>
                    {
                        array && array.map((element, index) => {
                                return <ProductCard key={index} productData={element} isFirst={index === 0 ? true : false} />
                        })
                    }
                    {/* Qui si passano le props per i filtri */}
                    <div className={`border h-fit w-fit rounded-lg border-slate-700 p-2 cursor-pointer mr-12`} onClick={handleExtend}><h4>Continua...</h4></div>
                </div>

                {/* Desktop right arrow */}
                <div className="z-10 absolute right-2 lg:right-[-70px] top-20 w-12 h-12 mt-6 px-2 bg-slate-200 rounded-full hidden md:flex items-center justify-center pt-1 cursor-pointer" onClick={scrollRight}><i class="fi fi-br-angle-right text-slate-500"></i></div>
            </div>
        </div>
    )
}

export default SlideCardsContainer;