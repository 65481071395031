import { useEffect, useState } from "react";
import { ProductsButton } from "../Buttons";
import { useSelector, useDispatch } from "react-redux";
import { selectSection } from "../../redux/mySectionSlice";

const MyProfileNavbar = () => {
    // handle selection
    const selected = useSelector((state) => state.mySection.selected);
    const dispatch = useDispatch();
    const handleSelection = (num) => {
        dispatch(selectSection(num))
    }

    //handle alert
    const alerts = useSelector((state) => state.login.requestsAmount);
    return (
        <div className="w-full flex flex-col lg:flex-row items-center md:justify-center gap-0">
            <ProductsButton text="Il tuo profilo" click={() => handleSelection(0)} selectedSection={selected === 0 ? true : false} width="100%" />
            <ProductsButton text="I tuoi prodotti in vendita" click={() => handleSelection(1)} selectedSection={selected === 1 ? true : false} width="100%" />
            <div className='flex flex-col items-center relative w-full'>
                {alerts !== 0 && <div className="w-8 h-8 rounded-2xl bg-teal-500 bg-opacity-80 flex justify-center items-center text-xs text-white absolute right-8 top-2 lg:top-10 lg:right-2">{alerts}</div>}
                <ProductsButton text="Richieste per i tuoi prodotti" click={() => handleSelection(2)} selectedSection={selected === 2 ? true : false} width="100%" />
            </div>
            <ProductsButton text="I tuoi prodotti venduti" click={() => handleSelection(3)} selectedSection={selected === 3 ? true : false} width="100%" />
            <ProductsButton text="I tuoi prodotti acquistati" click={() => handleSelection(4)} selectedSection={selected === 4 ? true : false} width="100%" />
            <ProductsButton text="Le tue richieste inviate" click={() => handleSelection(5)} selectedSection={selected === 5 ? true : false} width="100%" />
        </div>
    )
}

export default MyProfileNavbar;