import { Reorder } from "framer-motion";
import { useCallback, useEffect, useState } from "react";

const ReorderModal = ({ inputDataGallery, setInputData, coverPublicId, handleSelectCover, handleDeleteImage }) => {
    // Creo l'array di indici che verrà gestito da Reorder.Group
    const [items, setItems] = useState(null)

    // Inizializzazione di itemsIndex
    useEffect(() => {
        setItems(inputDataGallery)
    }, [inputDataGallery])

    // Gestisce il riordino
    const handleReorder = (newOrder) => {
        setItems(newOrder)
        setInputData(prevState => ({
            ...prevState,
            gallery: newOrder
        }))
    }
    return (
        <div>
            {
                items && items.length > 0 &&
                <Reorder.Group axis="y" values={items} onReorder={handleReorder} className="flex flex-col gap-2">
                    {
                        items.map((element, index) => {
                            let cover = false;
                            if (coverPublicId) {
                                if (element.public_id === coverPublicId) {
                                    cover = true
                                }
                            }
                            return < Reorder.Item key={element.public_id} value={element} >
                                <div className={`w-full flex gap-8 items-center p-4 rounded bg-white shadow-lg ${cover ? 'border-2 border-[#225424]' : 'border-2 border-neutral-300'} relative`}>
                                    <div className="cursor-pointer border pb-1 pt-2 rounded-full"><i className="fi fi-br-menu-dots-vertical text-xl"></i></div>
                                    <div className="flex items-center gap-2 cursor-pointer h-24 w-24 overflow-hidden rounded" onClick={() => handleSelectCover(index)}>
                                        <img src={element.resizedUrl} className="h-full w-full object-cover" />
                                    </div>
                                    {cover && <div className="absolute bottom-0 right-2 text-xs md:text-xl text-[#225424] italic">Copertina</div>}
                                    {<i className="fi fi-rr-circle-xmark text-3xl cursor-pointer absolute top-2 right-2" id={element.public_id} onClick={handleDeleteImage}></i>}
                                </div>
                            </Reorder.Item>
                        })
                    }
                </Reorder.Group>
            }
        </div >
    )
}

export default ReorderModal