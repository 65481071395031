import { useRef } from "react";

const SlidePhotoGallery = ({ photoArray, showImage }) => {
    const scrollContainerRef = useRef();
    return (
        <div className="w-[360px] md:w-[540px] flex flex-col items-center">
            {/* Photos */}
            <div className="w-full flex gap-4 justify-start items-center flex-nowrap overflow-x-auto" ref={scrollContainerRef} >
                {
                    photoArray && photoArray.length > 0 &&
                    photoArray.map((element, index) => {
                        return <div key={index} id={element.secure_url} className="min-w-[100px] h-[100px] overflow-hidden rounded-xl cursor-pointer" onClick={() => showImage(element.secure_url)}><img src={element.resizedUrl} className="w-full h-full object-cover" /></div>
                    })
                }
            </div>
            <div className="w-full md:hidden flex justify-center mt-2"><i class="fi fi-sr-swipe-left text-neutral-200 text-xl"></i></div>
        </div>
    )
}

export default SlidePhotoGallery