import { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { osm } from '../maptiler/osmProvider.js';
import MapEvent from './MapEvent.jsx';
import locationIcon from '../images/gun_marker.png';

const RegisterMapView = ({ onMapClick }) => {

    const center = [44.93742372117642, 10.40875110563502];
    const zoomLevel = 8;
    const mapRef = useRef();

    const [position, setPosition] = useState(null);

    const markerIcon = new Icon({
        iconUrl: locationIcon,
        iconSize: [80, 80]
    })
    useEffect(() => {
        console.log(locationIcon)
    }, [locationIcon])
    return (
        <div className='w-[380px] md:w-[600px] lg:w-[800px] xl:w-[1000px] my-8 border-2 rounded'>
            <MapContainer className="h-[380px] w-full !cursor-pointer" center={center} zoom={zoomLevel}>
                <TileLayer url={osm.maptiler.url} attribution={osm.maptiler.attribution} />
                <MapEvent onMapClickEvent={onMapClick} setPosition={setPosition} />
                {
                    position &&
                    <Marker position={position} icon={markerIcon}>
                    </Marker>
                }
            </MapContainer>
        </div>
    )
}

export default RegisterMapView;