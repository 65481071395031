import { useEffect, useState } from "react";
import UserAvatar from "../UserAvatar";

const MessageCard = ({ userId, messageData }) => {
    const [formattedDate, setFormattedDate] = useState(null);
    const [myMessage, setMyMessage] = useState(null);
    useEffect(() => {
        if (messageData) {
            const newDate = new Date(messageData.createdAt);
            setFormattedDate(newDate.toLocaleString())
        }
        if (messageData.sender._id === userId) {
            setMyMessage(true);
        } else {
            setMyMessage(false);
        }
    }, [messageData])
    return (
        <div className={`w-full flex flex-col p-2 rounded-2xl ${myMessage ? ('self-start bg-teal-200') : ('self-end bg-slate-200')}`}>
            {messageData &&
                <div className={`${myMessage ? ('text-start') : ('text-end')}`}>
                    <div className={`flex gap-2 ${!myMessage ? ('flex-row-reverse') : ('')}`}>
                        <UserAvatar url={messageData.sender.avatarUrl} />
                        <div>
                            <h3>{messageData.sender.name} {messageData.sender.surname}</h3>
                            {formattedDate && <h4>Inviato il: {formattedDate}</h4>}
                        </div>
                    </div>
                    <p>{messageData.content}</p>
                </div>
            }
        </div>
    )
}

export default MessageCard;