import { useDispatch, useSelector } from "react-redux";
import Map from "../components/Map";
import { getPositionAsync } from "../redux/positionSlice";
import FetchLoader from '../components/FetchLoader';
import { GoHomeButton, TealButton } from "../components/Buttons";
import ProductsAmount from "../components/ProductsAmount";
import { useEffect } from "react";
import { getMapProducts } from "../redux/mapProductsSlice";
import PanelCardsContainer from "../components/cardsContainers/PanelCardsContainer";
import { pageUp } from "../redux/mapFilterSlice";
import CustomHr from '../components/CustomHr';

const ProductsMapPage = () => {

    // Rilevo la posizione dell'utente al click sul button
    const { isPosition, lat, lng, country, isPositionLoading } = useSelector(state => state.position);
    const { page } = useSelector(state => state.mapFilters);
    const dispatch = useDispatch();

    // A posizione rilevata, mando la fetch per i prodotti
    useEffect(() => {
        if (isPosition && lat && lng) {
            dispatch(getMapProducts({ lat, lng, page }))
        }
    }, [isPosition, lat, lng])

    // Eseguo subito la chiamata fetch per i prodotti che hanno la posizione
    const { isLoading, error } = useSelector(state => state.mapProducts);
    const { allProducts, productsPage, totalElements } = useSelector(state => state.mapProducts.data);
    useEffect(() => {
        dispatch(getMapProducts({ lat: null, lng: null, page }))
    }, [])

    // Ogni volta che page cambia (l'utente preme il tasto 'mostra alto', parte un'altra chiamata)
    useEffect(() => {
        if (lat && lng) {
            dispatch(getMapProducts({ lat, lng, page }))
        } else {
            dispatch(getMapProducts({ lat: null, lng: null, page }))
        }
    }, [page, isPosition])

    const handleClick = () => {
        dispatch(pageUp())
    }

    useEffect(() => {
        console.log('allProducts: ', allProducts)
        console.log('productsPage: ', productsPage)
    }, [allProducts, productsPage])

    return (
        <div className="main-container">
            <GoHomeButton />
            {allProducts && <ProductsAmount number={totalElements} />}
            <div className="w-full px-2 flex flex-col items-center gap-4">

                <div className="w-full flex justify-center mt-8">
                    {
                        !isPosition && !isPositionLoading &&
                        <div className="flex flex-col items-center">
                            <button className={`bg-teal-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-teal-500 flex gap-2 justify-center w-full md:w-fit`} onClick={() => dispatch(getPositionAsync())}>
                                <div className="pt-0.5"><i class="fi fi-rr-location-crosshairs text-white"></i></div>
                                <div>Rileva la mia posizione</div>
                            </button>
                            <p>oppure</p>
                            <h3>Esplora la mappa per trovare i prodotti</h3>
                        </div>

                    }
                    {
                        isPositionLoading && !isPosition && <FetchLoader />
                    }
                    {
                        isPosition && !isPositionLoading &&
                        <div className="flex flex-col gap-4 items-center w-96 border-2 border-slate-500 rounded-xl p-4">
                            <h3>La tua posizione:</h3>
                            <div className="flex flex-col w-full">
                                <div className="p-2 bg-white rounded-full"><b>{lat}, {lng}</b></div>
                                <p className="text-teal-500">{country}</p>
                            </div>
                        </div>
                    }

                </div>

                <Map />

                {/* <div className="w-full my-8">
                    <CustomHr />
                </div> */}

                {
                    allProducts && !isLoading && !error &&
                    <div>
                        <h2 className='pl-2 w-full text-left mb-4 text-teal-500 font-normal border-b border-teal-500'>
                            {
                                isPosition ? (
                                    'Prodotti più vicini a te:'
                                ) : (
                                    'Ultimi prodotti inseriti:'
                                )
                            }
                        </h2>
                        <PanelCardsContainer objectArray={productsPage} isDistance backlink="products-map" />
                    </div>
                }

                {
                    !isLoading &&
                    productsPage &&
                    productsPage.length > 0 &&
                    productsPage.length < totalElements && /* Il button sparisce quando sono stati raggiunti tutti i prodotti */
                    <TealButton text="Mostra altro..." click={handleClick} />
                }
            </div>

        </div>
    )
}

export default ProductsMapPage