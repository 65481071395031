import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton, TealButton } from "../Buttons";
import { getUnlogged, setNewNames } from "../../redux/loginSlice";
import ProfileUserAvatar from "../ProfileUserAvatar";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import FetchLoader from "../FetchLoader";
import CryptoJS from "crypto-js";
import { getPositionAsync } from "../../redux/positionSlice";

const MyUserData = () => {

    // Catturo i dati dell'utente
    const { id, name, surname, phone, avatarUrl, basePosition } = useSelector(state => state.login);
    const { isPosition, lat, lng, country, isPositionLoading } = useSelector(state => state.position);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleLogout = () => {
        localStorage.removeItem('tattooswap_auth_token');
        dispatch(getUnlogged());
        window.location.href = "/";
    }

    const token = localStorage.getItem('tattooswap_auth_token');


    // Chiamo in backend i dati email e phone decryptati
    const [userContacts, setUserContacts] = useState({
        email: null,
        phone: null
    });
    useEffect(() => {
        if (id) {
            getContacts();
        }
    }, [id])
    const getContacts = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/users/get-user-contacts/${id}`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'GET', headers };
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('Debug result: ', result)
                const contacts = { email: result.payload.email, phone: result.payload.phone || null };
                setUserContacts(contacts);
            }
        } catch (error) {
            console.error('error: ', error)
        }
    }

    // Gestisco l'impostazione del nuovo numero di telefono
    const [isNumberEditing, setIsNumberEditing] = useState(false);
    const [newNumber, setNewNumber] = useState(phone);
    const handleNewNumber = (event) => {
        const value = parseInt(event.target.value, 10)
        setNewNumber(value)
    }
    const [fetchStatus, setFetchStatus] = useState('idle');
    const sendNewNumber = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/set-phone-number`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: id, phoneNumber: newNumber })
            });
            if (response.ok) {
                setFetchStatus('succeeded');
                backToHome();
            } else {
                const error = await response.json()
                console.log('Fetch error: ', error)
                setFetchStatus('rejected')
            }
        } catch (error) {
            console.log('Catch error: ', error)
            setFetchStatus('rejected')
        }
    }
    const backToHome = () => {
        setTimeout(() => {
            window.location.href = "/"
        }, 3000)
    }

    // handle upload image
    const [uploadedImage, setUploadedImage] = useState('');
    const fileInputRef = useRef(null);

    const handleInputImage = () => {
        fileInputRef.current.click()
    }

    const handleUploadImage = (event) => {
        const file = event.target.files[0];
        transformFile(file);
    }

    const transformFile = (file) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setUploadedImage(reader.result)
            }
        } else {
            setUploadedImage('');
        }
    }

    const removeImage = () => {
        setUploadedImage('');
        if (fileInputRef.current) {
            fileInputRef.current.value = null
        }
    }

    const [imageFetchStatus, setImageFetchStatus] = useState('idle');
    const sendImage = async () => {
        const data = { userId: id, newAvatar: uploadedImage };
        try {
            setImageFetchStatus('pending')
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/edit-avatar`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const result = await response.json();
                setImageFetchStatus('succeeded');
            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult);
                setImageFetchStatus('rejected')
            }
        } catch (error) {
            console.log('Catch error: ', error)
            setImageFetchStatus('rejected')
        }
    }
    useEffect(() => {
        if (imageFetchStatus === 'succeeded') {
            setTimeout(() => {
                window.location.reload();
            }, 3000)
        }
    }, [imageFetchStatus])

    // Gestisco la modifica di nome e cognome
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [isSurnameEditing, setIsSurnameEditing] = useState(false);
    const initialState = {
        name: null,
        surname: null
    }
    const [namesInputData, setNamesInputData] = useState(initialState);
    useEffect(() => {
        if (name && surname) {
            setNamesInputData({
                name,
                surname
            })
        }
    }, [name, surname])
    // Attivo gli input
    const handleEditing = (which, boo) => {
        if (!boo) {
            setIsNameEditing(false);
            setIsSurnameEditing(false);
            setNamesInputData({ name, surname });
        } else if (which === 'name') {
            setIsNameEditing(true);
            setIsSurnameEditing(false);
        } else if (which === 'surname') {
            setIsSurnameEditing(true);
            setIsNameEditing(false);
        }
    }
    // Gestisco l'inputData
    const handleNameInputData = (event) => {
        const { id, value } = event.target;
        setNamesInputData(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
    useEffect(() => {
        console.log('namesInputData: ', namesInputData)
    }, [namesInputData])

    // Invio i dati names
    const [isNamesLoading, setIsNameLoading] = useState(false);
    const [namesError, setNamesError] = useState(null);
    const sendNewNames = async () => {
        setIsNameLoading(true);
        const url = `${process.env.REACT_APP_BASE_URL}/users/set-new-names/${id}`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'PATCH', headers, body: JSON.stringify({ newNames: namesInputData }) }
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                dispatch(setNewNames(result.payload));
                setIsNameLoading(false);
                setIsNameEditing(false);
                setIsSurnameEditing(false);
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            setNamesError(error.message || 'Errore sconosciuto nel ricevere i dati');
            setIsNameLoading(false);
        }
    }

    // Gestisco la modifica della password
    const [isPasswordEditing, setIsPasswordEditing] = useState(false);
    const [isPasswordLoading, setIsPasswordLoading] = useState(false);
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [passwordError, setPasswordError] = useState(null);

    const sendPasswordMail = async () => {
        try {
            setIsPasswordLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/recovery-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: userContacts.email })
            });
            if (response.ok) {
                setIsEmailSent(true);
                setIsPasswordLoading(false);
            } else {
                const errorResult = await response.json();
                throw errorResult;
            }
        } catch (error) {
            console.log('Catch error: ', error)
            setPasswordError(error.message || "Errore nell'inviare l'email per il ripristino della password")
            setIsPasswordLoading(false);
        }
    }

    // Gestisco le impostazioni sulla posizione

    return (
        <div className="flex flex-col w-full border-2 p-4 gap-4 items-start">
            {/* No position alert */}
            {
                basePosition && !basePosition.lat && !basePosition.lng &&
                <div className="w-full bg-red-100 border-2 border-teal-500 p-2">
                    <p>La <b>posizione di base dei tuoi prodotti non è definita</b>. Essi sono comunque disponibili nel marketplace e possono essere trovati dagli altri utenti, ma NON compaiono nella pagina di visualizzazione della mappa dove gli utenti hanno la possibilità di trovare prodotti nelle vicinanze sfruttando la geolocalizzazione.</p>
                    <p> Puoi fare in modo che i tuoi prodotti compaiano anche nella sezione mappa, fissando una posizione per i tuoi prodotti.</p>
                    <Link to="/set-products-position?backlink=my-profile"><TealButton text="Fissa una posizione per i tuoi prodotti" /></Link>
                </div>
            }

            <h3 className='self-auto md:self-start text-teal-500 font-normal mb-4'>I tuoi dati:</h3>
            <ProfileUserAvatar url={`${uploadedImage ? uploadedImage : avatarUrl}`} click={handleInputImage} />
            <input
                type="file"
                accept="image/"
                id="photo"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleUploadImage} />
            {
                uploadedImage && imageFetchStatus === 'idle' &&
                <div className="flex gap-2">
                    <PrimaryButton text="Salva foto" click={sendImage} />
                    <SecondaryButton text="Annulla" click={() => setUploadedImage('')} />
                </div>
            }
            {imageFetchStatus === 'pending' && <div className="ml-8"><FetchLoader /></div>}
            {imageFetchStatus === 'succeeded' && <h3 className="text-teal-500">Avatar caricato con successo, attendi il refresh della pagina.</h3>}
            {imageFetchStatus === 'rejected' && <h3 className="text-red-500">Qualcosa è andato storto, ricarica la pagina e riprova.</h3>}
            <div className="mb-[-20px]">il tuo ID:</div>
            <h3>{id}</h3>

            <div className="flex gap-4 items-center h-[52px]">
                <div>Nome</div>
                {
                    !isNameEditing && !isNamesLoading && !namesError &&
                    <div className="flex gap-2 items-center">
                        <div className="p-2 border"><b>{name}</b></div>
                        {namesInputData.name && <div className="pt-2"><i className="fi fi-rr-pen-square text-4xl text-slate-300 cursor-pointer" onClick={() => handleEditing('name', true)}></i></div>}
                    </div>
                }
                {
                    isNameEditing && !isNamesLoading && !namesError &&
                    <div className="flex gap-2 items-center">
                        <div className="pt-2"><i className="fi fi-br-cross-circle text-4xl text-red-300 cursor-pointer" onClick={() => handleEditing('name', false)}></i></div>
                        <input id="name" type="text" value={namesInputData.name} onChange={handleNameInputData} />
                        <TealButton text="Conferma" click={sendNewNames} />
                    </div>
                }
                {
                    isNamesLoading &&
                    <FetchLoader />
                }
                {
                    namesError &&
                    <div className="text-red-500">Errore: {namesError}</div>
                }
            </div>
            <div className="flex gap-4 items-center h-[52px]">
                <div>Cognome</div>
                {
                    !isSurnameEditing && !isNamesLoading && !namesError &&
                    <div className="flex gap-2 items-center">
                        <div className="p-2 border"><b>{surname}</b></div>
                        {namesInputData.surname && <div className="pt-2"><i className="fi fi-rr-pen-square text-4xl text-slate-300 cursor-pointer" onClick={() => handleEditing('surname', true)}></i></div>}
                    </div>
                }
                {
                    isSurnameEditing && !isNamesLoading && !namesError &&
                    <div className="flex gap-2 items-center">
                        <div className="pt-2"><i className="fi fi-br-cross-circle text-4xl text-red-300 cursor-pointer" onClick={() => handleEditing('surname', false)}></i></div>
                        <input id="surname" type="text" value={namesInputData.surname} onChange={handleNameInputData} />
                        <TealButton text="Conferma" click={sendNewNames} />
                    </div>
                }
                {
                    isNamesLoading &&
                    <FetchLoader />
                }
                {
                    namesError &&
                    <div className="text-red-500">Errore: {namesError}</div>
                }
            </div>

            {/* EMAIL */}
            {/* Temporary disabled */}
            <div className="flex flex-col md:flex-row gap-4 items-center">
                <div>Email</div>
                <div className="p-2 border"><b>{userContacts.email || ''}</b></div>
            </div>

            {/*  PASSWORD */}
            <div className="flex gap-4 items-center">
                <div>Password</div>
                {!isPasswordEditing && <TealButton text="Modifica password" click={() => setIsPasswordEditing(true)} />}
                {
                    isPasswordEditing && !isEmailSent && !passwordError && !isPasswordLoading &&
                    <div className="flex flex-col gap-2">
                        <div>Vuoi modificare la password? (Ti sarà inviata un'email con le istruzioni per la modifica)</div>
                        <div className="flex gap-2">
                            <PrimaryButton text="Conferma" click={sendPasswordMail} />
                            <SecondaryButton text="Annulla" click={() => setIsPasswordEditing(false)} />
                        </div>
                    </div>
                }
                {
                    isEmailSent &&
                    <h3 className="text-teal-500">Ti è stata inviata un'email con le istruzioni per la modifica della password</h3>
                }
                {
                    passwordError &&
                    <div className="text-red-500">Errore: {passwordError}</div>
                }
                {
                    isPasswordLoading &&
                    <FetchLoader />
                }
            </div>

            <div className="flex flex-col gap-4 items-start text-left border p-2">
                <div>Numero di telefono:</div>
                {
                    !userContacts.phone &&
                    <div className="flex flex-col gap-2">
                        <h3 className="text-teal-500">Non hai un numero di telefono impostato</h3>
                        <h4>Impostare il numero di telefono è utile per facilitare lo scambio quando si acquista o si vende un prodotto. Esso viene mostrato SOLO all'utente con cui effettui lo scambio.</h4>
                        {
                            !isNumberEditing &&
                            <button className="bg-teal-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-teal-500 w-ull md:w-fit" onClick={() => setIsNumberEditing(true)}>Imposta numero di telefono</button>
                        }
                        {
                            isNumberEditing && fetchStatus === 'idle' &&
                            <div className="flex flex-col gap-2">
                                <input type="number" onChange={handleNewNumber}></input>
                                <div className="flex gap-2">
                                    <PrimaryButton text="Invia" click={sendNewNumber} />
                                    <SecondaryButton text="Annulla" click={() => setIsNumberEditing(false)} />
                                </div>
                            </div>
                        }
                        {
                            isNumberEditing && fetchStatus === 'pending' &&
                            <FetchLoader />
                        }
                        {
                            isNumberEditing && fetchStatus === 'succeeded' &&
                            <h3>Numero di telefono aggiornato. Verrai reindirizzato fra 3 secondi.</h3>
                        }
                        {
                            isNumberEditing && fetchStatus === 'rejected' &&
                            <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>
                        }
                    </div>
                }
                {
                    userContacts.phone &&
                    <div>
                        {
                            !isNumberEditing &&
                            <div className="flex gap-2 items-center">
                                <b>{userContacts.phone}</b>
                                <i className="fi fi-rr-pen-square text-3xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => setIsNumberEditing(true)}></i>
                            </div>
                        }
                        {
                            isNumberEditing && fetchStatus === 'idle' &&
                            <div className="flex flex-col gap-2">
                                <input type="number" placeholder={phone} onChange={handleNewNumber}></input>
                                <div className="flex gap-2">
                                    <PrimaryButton text="Invia" click={sendNewNumber} />
                                    <SecondaryButton text="Annulla" click={() => setIsNumberEditing(false)} />
                                </div>
                            </div>
                        }
                        {
                            isNumberEditing && fetchStatus === 'pending' &&
                            <FetchLoader />
                        }
                        {
                            isNumberEditing && fetchStatus === 'succeeded' &&
                            <h3>Numero di telefono aggiornato. Verrari reindirizzato fra 3 secondi.</h3>
                        }
                        {
                            isNumberEditing && fetchStatus === 'rejected' &&
                            <h3>Qualcosa è andato storto, ricarica la pagina e riprova.</h3>
                        }
                    </div>
                }
            </div>

            {/* POSITION */}
            {/* Di queste due sezioni dovresti farne dei componenti */}
            <div className="flex flex-col gap-4 items-start w-full border p-2">
                {<div id="basePosDiv">La tua posizione:</div>}
                {
                    isPosition &&
                    lat &&
                    lng &&
                    <div className="flex flex-col items-start w-full">
                        <div className="p-2 border"><b>{lat}, {lng}</b></div>
                        <p className="text-teal-500">{country || ''}</p>
                    </div>
                }
                {
                    isPosition &&
                    (!lat || !lng) &&
                    <h3 className="text-teal-500">Non rilevata</h3>
                }
                {/* Integrare il loading di isPositionLoading (non funziona perfettamente il booleano) */}
                {
                    !isPosition &&
                    <button className={`bg-teal-400 text-slate-50 px-4 py-3 rounded-lg hover:bg-teal-500 flex gap-2 justify-center w-full md:w-fit`} onClick={() => dispatch(getPositionAsync())}>
                        <div className="pt-0.5"><i class="fi fi-rr-location-crosshairs text-white"></i></div>
                        <div>Rileva la mia posizione</div>
                    </button>
                }
            </div>
            <div className="flex flex-col gap-4 items-start w-full border p-2">
                <div id="basePosDiv">La posizione dei tuoi prodotti:</div>
                {
                    basePosition &&
                    basePosition.lat &&
                    basePosition.lng &&
                    <div className="flex flex-col items-start w-full">
                        <div className="p-2 border"><b>{basePosition.lat}, {basePosition.lng}</b></div>
                        <p className="text-teal-500">{basePosition.country || ''}</p>
                        <Link to="/set-products-position?backlink=my-profile"><TealButton text="Modifica posizione prodotti" width="100%" /></Link>
                    </div>
                }
                {
                    basePosition &&
                    (!basePosition.lat || !basePosition.lng) &&
                    <div className="flex flex-col w-full items-start">
                        <h3 className="text-red-400">Posizione dei prodotti non rilevata</h3>
                        <p>Imposta una posizione dei tuoi prodotti per aumentare le possibilità che vengano trovati dagli altri utenti:</p>
                        <Link to="/set-products-position?backlink=my-profile"><TealButton text="Imposta posizione prodotti" width="100%" /></Link>
                    </div>
                }
            </div>

            {/* ELIMINA ACCOUNT */}

            <h5 className="underline mt-16 cursor-pointer">Vuoi eliminare il tuo account?</h5>

            <div className="border p-2 w-full">
                <SecondaryButton text="Esegui il logout " click={handleLogout} />
            </div>
        </div>
    )
}
export default MyUserData;