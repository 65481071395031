import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const getAllProducts = createAsyncThunk(
    'products/allProducts',
    async ({ filtersObject, page }) => {
        let url = `${process.env.REACT_APP_BASE_URL}/products/get-all-products-new`; // Da sostituire col nuovo nome rotta
        let headers = { 'Content-Type': 'application/json' };
        let options = { method: 'POST', headers, body: JSON.stringify({ filtersObject, page }) };
        const response = await fetch(url, options);
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            const error = await response.json();
            throw new Error(error.message || 'Errore sconosciuto');
        }
    }
)

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        data: {
            totalElements: null,
            page: null,
            totalePages: null,
            products: []
        },
        isLoading: false,
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllProducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllProducts.fulfilled, (state, action) => {
                state.data.totalElements = action.payload.payload.totalElements;
                state.data.totalPages = action.payload.payload.totalPages;
                // Se è la prima pagina sostituisco l'array, altrimenti aggiungo il nuovo array alle pagine esistenti
                if (action.payload.payload.page === 1) {
                    state.data.products = action.payload.payload.products
                } else {
                    if (action.payload.payload.page > state.data.page) {
                        state.data.products = [
                            ...state.data.products,
                            ...action.payload.payload.products
                        ]
                    }
                }
                state.data.page = action.payload.payload.page;
                state.isLoading = false;
                state.error = null;
            })
            .addCase(getAllProducts.rejected, (state, action) => {
                state.isLoading = false;
                if (action.error.message === 'Nessun prodotto trovato') {
                    state.data.products = [];
                } else {
                    state.error = action.error;
                }
            })
    }
})

export default productsSlice.reducer;