import { useState, useEffect } from "react";
import RegisterMapView from "../components/RegisterMapView.jsx";
import { DisabledButton, GoHomeButton, PrimaryButton, SecondaryButton } from '../components/Buttons.jsx'
import FetchLoader from "../components/FetchLoader.jsx";
import { getCountryFunction } from "../components/utils/getCountryFunction.js";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

const SetProductsPosition = () => {

    // Imposto il backlinh per GoTohome
    const [searchParams] = useSearchParams();
    const backlink = searchParams.get('backlink');
    //----- GESTIONE CLICK SULLA MAPPA -----//

    const [userCoordinates, setUserCoordinates] = useState(null);
    const [userCountry, setUserCountry] = useState(null);
    const [sendButtonAvailable, setSendButtonAvailable] = useState(false);

    const handleMapClick = (coordinates) => {
        setSendButtonAvailable(false);
        setUserCoordinates(coordinates);
    }

    const handleSetCountry = async (lat, lng) => {
        const country = await getCountryFunction(lat, lng);
        setUserCountry(country)
    }

    useEffect(() => {
        if (userCoordinates) {
            handleSetCountry(userCoordinates.lat, userCoordinates.lng)
        }
    }, [userCoordinates])

    // Send data fetch
    const userId = useSelector((state) => state.login.id);
    const token = localStorage.getItem('tattooswap_auth_token');
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [fetchData, setFetchData] = useState(null);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (userCountry) {
            setFetchData({
                userId: userId,
                lat: userCoordinates.lat,
                lng: userCoordinates.lng,
                country: userCountry
            })
        }
    }, [userCountry])
    useEffect(() => {
        if (fetchData) {
            setSendButtonAvailable(true);
        }
    }, [fetchData])
    const sendPosition = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/set-position-manually`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(fetchData)
            });
            if (response.ok) {
                const result = await response.json();
                console.log('Fetch ok: ', result);
                setFetchStatus('succeeded');
                backToHome();
            } else {
                const error = await response.json();
                setFetchStatus('rejected');
                console.log('Fetch error: ', error);
            }
        } catch (error) {
            console.log('Catch error: ', error)
            setFetchStatus('rejected');
        }
    }

    const backToHome = () => {
        console.log('backToHome called')
        setTimeout(() => {
            window.location.href = "/"
        }, 3000)
    }

    return (
        <div className="main-container">
            <GoHomeButton backlink={backlink} />
            <div className="box">
                <h3 className="mb-4">Indica la posizione dei tuoi prodotti</h3>
                <div className="flex flex-col gap-1">
                    <h4 className="text-teal-500 text-sm">(Questo passaggio è importante</h4>
                    <h4 className="text-teal-500 text-sm">per fare in modo</h4>
                    <h4 className="text-teal-500 text-sm">che i tuoi prodotti vengano</h4>
                    <h4 className="text-teal-500 text-sm">visti dagli utenti più vicini a te!)</h4>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                    <h4 className="text-sm">La posizione che imposti</h4>
                    <h4 className="text-sm">sarà applicata a TUTTI i tuoi prodotti;</h4>
                    <h4 className="text-sm">essa può corrispondere alla posizione</h4>
                    <h4 className="text-sm">dello studio dove lavori</h4>
                    <h4 className="text-sm">oppure casa tua.</h4>

                    <p>Selezionala nella mappa qua sotto.</p>
                    <p>Utilizza lo zoom per essere più preciso.</p>

                    <h4 className="text-teal-500 text-sm mt-4">(La tua posizione personale NON verrà comunque mostrata a nessuno)</h4>

                </div>

                <div>

                    {
                        userCoordinates &&
                        <div className="flex flex-col items-center">
                            <h3>Coordinate:</h3>
                            <h3 className="mb-4 text-teal-500 hidden md:block">{userCoordinates.lat}, {userCoordinates.lng}.</h3>
                            <h4 className="mb-4 text-teal-500 md:hidden">{userCoordinates.lat}, {userCoordinates.lng}.</h4>
                            <h3>Paese e indirizzo:</h3>
                            <h3 className="mb-4 text-teal-500 hidden md:block">{userCountry}</h3>
                            <h4 className="mb-4 text-teal-500 md:hidden">{userCountry}</h4>
                            {fetchStatus === 'pending' && <FetchLoader />}
                            {
                                fetchStatus === 'succeeded' &&
                                <div>
                                    <h3 className="text-teal-500 mt-8">Posizione inserita con successo.</h3>
                                    <h3>verrai reindirizzato in homepage fra 3 secondi</h3>
                                </div>
                            }
                            {fetchStatus === 'idle' && sendButtonAvailable && <PrimaryButton text="Prosegui" click={sendPosition} />}
                            {fetchStatus === 'idle' && !sendButtonAvailable && <DisabledButton text="Prosegui" />}
                            {fetchStatus === 'rejected' && error && <h3>Qualcosa è andato storto, ricarica la pagina e riprova</h3>}
                        </div>
                    }
                    {fetchStatus === 'idle' && <RegisterMapView onMapClick={handleMapClick} />}
                </div>
            </div>
        </div>
    )
}

export default SetProductsPosition;