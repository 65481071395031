import { useState, useEffect } from "react";
import UserAvatar from '../UserAvatar.jsx';

const UserCard = ({ index, userData, isEven }) => {
    useEffect(() => {
        if (userData) {
            formatDate();
        }
    }, [userData])

    // Format date
    const [formattedDate, setFormattedDate] = useState('');
    const formatDate = () => {
        const date = new Date(userData.createdAt);
        const formattedDate = date.toLocaleString(); 
        setFormattedDate(formattedDate);
    }
    return (
        <div className="flex w-full gap-2">

            <div className="w-4">
                {index}.
            </div>

            <div className={`flex flex-1 gap-4 p-2 rounded ${isEven ? 'bg-teal-50' : 'bg-teal-100'}`}>
                {/* User avatar */}
                <UserAvatar url={userData.avatarUrl} />
                {/* User info */}
                <div className="flex flex-col items-start text-start">
                    <h3>{userData.name} {userData.surname}</h3>
                    <h4>{userData._id}</h4>
                    <p>{userData.email}</p>
                    {userData.country && <p>{userData.country}</p>}
                    {formattedDate && <p className="text-sm text-teal-500">Utente registrato il {formattedDate}</p>}
                </div>
            </div>

        </div>
    )
}

export default UserCard;