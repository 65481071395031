import { createSlice } from "@reduxjs/toolkit";

const mySectionSlice = createSlice({
    name: 'mySection',
    initialState: {
        selected: 0
    },
    reducers: {
        selectSection: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const { selectSection } = mySectionSlice.actions;

export default mySectionSlice.reducer;