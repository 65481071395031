import { useEffect, useState } from "react";
import UserAvatar from "../UserAvatar";
import { PrimaryButton, TealButton } from "../Buttons";
import { useNavigate } from "react-router-dom";

const ConversationCard = ({ index, data, isMyProduct, alert }) => {

    // Get conversation data
    const [lastMessageData, setLastMessageData] = useState({
        content: null,
        createdAt: null
    });
    useEffect(() => {
        const lastIndex = data.messages.length - 1;
        const newDate = new Date(data.messages[lastIndex].createdAt)
        setLastMessageData({
            content: data.messages[lastIndex].content,
            createdAt: newDate.toLocaleString()
        });
    }, [data]);

    // Link to conversation
    const navigate = useNavigate();
    const goToConversation = (conversationId) => {
        navigate(`conversations/${conversationId}`)
    }
    return (

        <div className={`border-2 border-teal-200 w-full flex flex-col md:flex-row md:justify-center gap-4 p-4 rounded-xl items-center ${alert ? 'bg-teal-100 relative' : ''}`}>
            {alert && <div className="absolute top-1 right-1 text-xs text-teal-500">Nuovo messaggio</div>}
            <div className="w-full md:w-fit flex gap-2">
                <div className="ml-2">
                    {data &&
                        <div className="flex flex-col gap-2 w-24">
                            <div className="overflow-hidden w-24 h-24 rounded-xl"><img src={data.objectReference.photo.resizedUrl} alt="product photo" className="h-full w-full object-cover" /></div>
                            <h4>{data.objectReference.title}</h4>
                        </div>}
                </div>
                <div className="flex flex-col border border-teal-200 p-2 rounded-3xl">
                    <UserAvatar url={data.supplierUser.avatarUrl} />
                    <div className="mt-[-4px] mb-[-10px]"><i className="fi fi-sr-caret-up text-teal-500 text-xl"></i></div>
                    <UserAvatar url={data.applicantUser.avatarUrl} />
                </div>
            </div>
            <div className="w-full flex flex-col justify-center items-start text-start">
                {data && isMyProduct && <h2>{data.applicantUser.name} {data.applicantUser.surname}</h2>}
                {data && !isMyProduct && <h2>{data.supplierUser.name} {data.supplierUser.surname}</h2>}
                <TealButton text="Apri conversazione" click={() => { goToConversation(data._id) }} />
                {lastMessageData && <h4 className="mt-4 mb-[-8px]">Ultimo messaggio: {lastMessageData.createdAt}</h4>}
                {lastMessageData && <p className="text-slate-400 italic">"{lastMessageData.content}"</p>}
            </div>
        </div >
    )
}

export default ConversationCard;