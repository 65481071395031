import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getAllConversations = createAsyncThunk(
    'conversasions/getConversations',
    async ({ id, page, pageSize }) => {
        const token = localStorage.getItem('tattooswap_auth_token');
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/conversations/get-user-conversations?userId=${id}&page=${page}&pageSize=${pageSize}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        if (response.ok) {
            const conversationsData = await response.json();
            return conversationsData;
        } else {
            const errorData = await response.json();
            throw new Error(errorData);
        }
    }
)

const conversationsSlice = createSlice({
    name: 'conversations',
    initialState: {
        data: [],
        error: null,
        fetchStatus: 'idle'
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllConversations.pending, (state) => {
                state.fetchStatus = 'loading'
            })
            .addCase(getAllConversations.fulfilled, (state, action) => {
                state.data = action.payload;
                state.fetchStatus = 'succeeded';
            })
            .addCase(getAllConversations.rejected, (state, action) => {
                state.error = action.error.message;
                state.fetchStatus = 'failed';
            })
    }
})

export default conversationsSlice.reducer;