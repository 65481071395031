import { GoHomeButton, PrimaryButton } from "../components/Buttons";
import { useEffect, useState } from "react";
import FetchLoader from "../components/FetchLoader";

const RecoveryPassword = () => {
    const [email, setEmail] = useState({
        email: ''
    })
    const [fetchStatus, setFetchStatus] = useState('idle');
    const handleInputMail = (event) => {
        const { value } = event.target;
        setEmail(prevData => ({
            ...prevData,
            email: value
        }))
    }
    const sendMail = async () => {
        try {
            setFetchStatus('pending');
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/recovery-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(email)
            });
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                setFetchStatus('fulfilled');
                setTimeout(() => {
                    window.location.href = "/";
                }, 3000)

            } else {
                const errorResult = await response.json();
                console.log('Fetch error: ', errorResult)
                setFetchStatus('rejected');
            }
        } catch (error) {
            console.log('Catch error: ', error)
        }
    }
    return (
        <div className="main-container">
            <GoHomeButton />
            <div className="box md:shadow-2xl">
                {
                    fetchStatus === 'idle' ? (
                        <>
                            <h3>Inserisci la tua email per recuperare la password</h3>
                            <label htmlFor="email" className="self-center">Email</label>
                            <input type="text" id="email" className="w-96" onChange={handleInputMail} />
                            <div className='text-slate-500 text-xs self-start mb-8 self-center'>Inserisci un'email valida.</div>
                            <PrimaryButton text="Invia" click={sendMail} />
                        </>
                    ) : (
                        <>
                            {fetchStatus === 'pending' && <FetchLoader />}
                            {fetchStatus === 'rejected' && <h3>Utente con email {email.email} non trovato.</h3>}
                            {
                                fetchStatus === 'fulfilled' &&
                                <>
                                    <h3>Ti è stata inviata un'email per ripristinare la password.</h3>
                                    <p>Verrari reindirizzat* in homepage fra 3 secondi.</p>
                                </>
                            }
                        </>
                    )
                }
            </div>
        </div >
    )
}

export default RecoveryPassword;