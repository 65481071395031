import { Link } from 'react-router-dom';
import logo from '../images/logo-500.png'
import { DisabledButton, TealButton } from './Buttons';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isValidEmail } from './utils/isValidEmail';
import FetchLoader from '../components/FetchLoader';

const Footer = () => {
    const { isLogged, isMarketingAvailable } = useSelector(state => state.login)
    const [emailInput, setEmailInput] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const handleEmailInput = (event) => {
        const { value } = event.target;
        setEmailInput(value)
    }

    // Abilito il tasto invia solo quando la stringa è un'email valida
    useEffect(() => {
        const boo = isValidEmail(emailInput)
        boo ? setIsValid(true) : setIsValid(false);
    }, [emailInput])

    // Fetch di invio dell'email
    const [fetchStatus, setFetchStatus] = useState('idle');
    const [error, setError] = useState(null);
    const sendEmail = async () => {
        setFetchStatus('loading');
        const url = `${process.env.REACT_APP_BASE_URL}/users/set-marketing-available`;
        const headers = { 'Content-Type': 'application/json' };
        const body = JSON.stringify({email: emailInput});
        console.log('body: ', body)
        const options = { method: 'PATCH', headers, body }
        try {
            const response = await fetch(url, options);
            if (response.ok) {
                const result = await response.json();
                console.log('result: ', result);
                setFetchStatus('succeeded');
            } else {
                const error = await response.json();
                throw new Error(error.message || "Errore nell'invio dell'email")
            }
        } catch (error) {
            console.error(error);
            setError(error.message || "Errore nell'invio dell'email, riprova.")
            setFetchStatus('failed');
        }
    }
    useEffect(() => {
        console.log('fetchStatus: ', fetchStatus)
        if(fetchStatus === 'succeeded') {
            setTimeout(() => {
                window.location.href = "/"
            }, 3000)
        }
    }, [fetchStatus])
    return (
        <div className="bg-slate-700 text-slate-50 flex flex-col items-center p-12 md:px-48 xl:px-96 mb-24 md:mb-0">
            <div className='flex flex-col xl:flex-row gap-8 items-center text-center xl:text-start my-16'>
                <div className={`flex gap-8 ${(isLogged && !isMarketingAvailable) && '2xl:min-w-[300px]'}`}>
                    <Link to="https://instagram.com/tattoo.swap" target="_blank"><div className='text-6xl cursor-pointer'><i className="fi fi-brands-instagram"></i></div></Link>
                    <Link to="https://www.facebook.com/tattooswap.it" target="_blank"><div className='text-6xl cursor-pointer'><i className="fi fi-brands-facebook"></i></div></Link>
                </div>
                {
                    isLogged && !isMarketingAvailable &&
                    <div className='flex flex-col gap-4 items-center xl:items-start'>
                        {fetchStatus === 'idle' && <div>Vorrei restare sempre aggiornat* sulle novità del mondo tattoo e ricevere comunicazioni promozionali:</div>}
                        {fetchStatus === 'idle' && <input type="email" id="email" placeholder='Inserisci la tua email' className='text-black text-center max-w-96 xl:text-start' onChange={handleEmailInput} />}
                        {
                            fetchStatus === 'idle' && isValid &&
                            <div className='w-full max-w-96 flex justify-center xl:justify-start'><TealButton text="Iscrivimi" click={sendEmail} /></div>
                        }
                        {
                            fetchStatus === 'idle' && !isValid &&
                            <div className='w-full max-w-96 flex justify-center xl:justify-start'><DisabledButton text="Iscrivimi" /></div>
                        }
                        {fetchStatus === 'loading' && <FetchLoader />}
                        {
                            fetchStatus === 'failed' && error &&
                            <div className='text-red-500'>{error}</div>
                        }
                        {
                            fetchStatus === 'succeeded' &&
                            <h3 className='text-teal-500'>Email inviata con successo</h3>
                        }

                        <div className='italic'>(Potrai disiscriverti in qualsiasi momento)</div>
                    </div>
                }
            </div>


            <h3 className='text-slate-50'>Cos'è Tattooswap?</h3>
            <p className='text-center text-slate-50'>Tattooswap è una piattaforma di <span className='text-teal-500'>compravendita di articoli usati per tatuatori</span>. Se sei un tatuatore professionista (o un apprendista), sarai spesso in cerca di qualcosa che può servirti o potresti avere in studio qualcosa di cui sbarazzarti.</p>
            <p className='text-center text-slate-50'>Molte persone preferiscono acquistare prodotti nuovi, ma se non è strettamente necessario, noi cerchiamo di incentivare lo scambio di prodotti in buono stato di seconda mano. Tattooswap ti aiuta a trovare attrezzatura che stai cercando nelle vicinanze, in modo completamente gratuito.</p>
            <p className='text-center text-slate-50'>Essendo un marketplace <span className='text-teal-500'>geolocalizzato</span> favorisce gli scambi fra utenti privati che si trovano più vicino uno all'altro.</p>
            <p className='text-center text-slate-50'>Potrebbe esserci un tuo collega non distante da te che sta vendendo quella tattoo pen che cerchi da tempo...</p>
            <div className='flex items-center gap-4'>
                <i class="fi fi-br-hand-back-point-right text-white text-xl mb-[-10px]"></i>
                <div className='my-2 p-4 border border-slate-500 rounded text-slate-50 italic'>Evita di comprare inutilmente attrezzatura nuova, il nostro settore è stracolmo di articoli che possono essere acquistati usati.</div>
            </div>
            <div className='flex items-center gap-4'>
                <i class="fi fi-br-hand-back-point-right text-white text-xl mb-[-10px]"></i>
                <div className='my-2 p-4 border border-slate-500 rounded text-slate-50 italic'>Metti in vendita anche tu le cose che non usi in studio.</div>
            </div>

            <i class="fi fi-rr-hand-holding-seeding text-teal-200 text-6xl mt-8"></i>
            <h3 className='text-center text-teal-200'>Favorisci il riciclo delle cose, da' nuova vita ad attrezzatura che non usi e contribuisci a ridurre gli sprechi.</h3>
            <div className="mt-12 mb-16 md:mb-0 text-black">Powered by<span className="font-bold ml-1 text-slate-50">Tattooswap</span></div>
        </div>
    )
}

export default Footer;