import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PanelCardsContainer from "../components/cardsContainers/PanelCardsContainer";
import AdvBlock from "../components/AdvBlock";
import { GoHomeButton } from "../components/Buttons";
import { selectPage } from "../redux/pageSlice";

const FavoritePage = () => {
    const reduxUserPosition = useSelector((state) => state.position);
    const reduxFavouritesItem = useSelector((state) => state.login.favourites);
    const token = localStorage.getItem('tattooswap_auth_token');
    const [favouritesArray, setFavouritesArray] = useState(null)

    const getFavouritesObject = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/products/get-products-by-id-array`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idArray: reduxFavouritesItem, userPosition: reduxUserPosition })
            });
            const object = await response.json();
            if (response.ok) {
                setFavouritesArray(object.payload)
            } else {
                console.log('Object error: ', object)
            }
        } catch (error) {
            console.error('Catch error: ', error)
        }
    }

    useEffect(() => {
        if (reduxFavouritesItem) {
            getFavouritesObject();
        }
    }, [reduxFavouritesItem])

    useEffect(() => {
        console.log('favouritesArray: ', favouritesArray)
    }, [favouritesArray])

    return (
        <div className="main-container">
            <GoHomeButton />
            <h2>Prodotti preferiti</h2>
            {favouritesArray && <PanelCardsContainer objectArray={favouritesArray} />}
            {/* <AdvBlock /> */}
        </div>
    )
}

export default FavoritePage;