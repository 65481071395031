import { useState, useEffect } from "react";
import FetchLoader from '../FetchLoader.jsx';
import UserAvatar from "../UserAvatar.jsx";
import { Link } from "react-router-dom";
import MainCard from "./MainCard.jsx";

const BuyedProductCard = ({ productData }) => {


    // Eseguo la fetch per avere i contatti del supplier
    const token = localStorage.getItem('tattooswap_auth_token');
    const [supplierData, setSupplierData] = useState(null)
    const [fetchStatus, setFetchStatus] = useState('idle');
    const getSupplierData = async () => {
        try {
            setFetchStatus('pending')
            console.log('productData.supplier: ', productData.supplier)
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/get-user-contacts/${productData.supplier._id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const result = await response.json();
                setSupplierData(result.payload);
                setFetchStatus('succeeded');
            } else {
                const errorData = await response.json();
                console.error('Errore nella richiesta.', errorData)
                setFetchStatus('error');
            }
        } catch (error) {
            console.error('Catch error: ', error);
            setFetchStatus('error');
        }
    }


    useEffect(() => {
        if(productData) {
            getSupplierData();
        }
    }, [productData])
    return (
        <div className='flex flex-col w-full border-2 p-4 gap-4'>
            
            <MainCard data={[productData._id, productData.coverImage.resizedUrl, productData.title, productData.category, productData.price]} />

            {/* Message */}
            <div className="bg-teal-100 p-2 flex flex-col gap-1 items-center pt-6">
                <h4>Ecco i dati dell'utente che ti ha venduto questo oggetto.</h4>
                <p><i>Contattalo via email per accordarti sullo scambio e il pagamento.</i></p>
                <div className="text-sm text-teal-500 mb-[-10px]">Email proprietario:</div>
                <h3 className="text-teal-700">{supplierData && supplierData.email}</h3>
                <div className="text-sm text-teal-500 mb-[-10px]">Numero di telefono:</div>
                {supplierData && supplierData.phone ? (
                    <>
                        <h2 className="text-slate-50 bg-teal-500 rounded p-2 mt-2"><Link to={`https://wa.me/${supplierData.phone}`}>{supplierData.phone}</Link></h2>
                        <h4>Clicca sul numero di telefono per contattare l'utente</h4>
                    </>
                ) : (
                    <h3 className="text-teal-700">Il proprietario non ha impostato nessun numero</h3>
                )
                }
            </div>

            {/* Supplier data */}
            <div className="flex flex-col md:flex-row gap-4 border-2 border-teal-200 p-4 mt-[-15px]">
                {/* left-photo */}
                <div>
                    <UserAvatar url={supplierData && supplierData.avatarUrl} />
                </div>

                {/* right-info */}
                <div className="text-left flex flex-col gap-2 w-full">
                    <h4>ID Utente:</h4>
                    <h4>{productData.supplier._id}</h4>
                    <div className="text-sm text-teal-500 mb-[-10px]">Nome</div>
                    <div>{productData.supplier.name}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Cognome</div>
                    <div>{productData.supplier.surname}</div>
                    <div className="text-sm text-teal-500 mb-[-10px]">Si trova a</div>
                    <div>x km di distanza da te</div>

                </div>
            </div>

        </div>
    )
}

export default BuyedProductCard;