import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DangerButton, PrimaryButton, SecondaryButton } from "../Buttons";
import CategoryTag from "../CategoryTag";
import { useSelector } from "react-redux";
import FetchLoader from "../FetchLoader";

const MainCard = ({ data, isCrud }) => {
    const [id, cover, title, category, price, gallery] = data;
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const handleEditing = () => {
        navigate(`/add-edit-product?id=${id}`)
    }

    // Invio fetch per eliminazione prodotto
    const [deleteFetchStatus, setDeleteFetchStatus] = useState('idle');
    const [deleteError, setDeleteError] = useState(null);
    const userId = useSelector(state => state.login.id);
    const sendDelete = async () => {
        setDeleteFetchStatus('loading');
        let imagesIdArray = [];
        if (gallery.length > 0) {
            gallery.forEach(element => {
                imagesIdArray.push(element.public_id)
            })
        };
        const body = {
            productId: id,
            userId: userId,
            imagesIdArray: imagesIdArray
        }
        console.log('body: ', body);
        const url = `${process.env.REACT_APP_BASE_URL}/products/delete-product`;
        const token = localStorage.getItem('tattooswap_auth_token');
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` };
        const options = { method: 'DELETE', headers, body: JSON.stringify(body) }
        try {
            const response = await fetch(url, options);
            if(response.ok) {
                const result = await response.json();
                setDeleteFetchStatus('succeeded');
                setTimeout(() => {
                    window.location.href = "/"
                }, 3000)
            } else {
                const error = await response.json();
                throw error
            }
        } catch (error) {
            console.error(error);
            setDeleteError(error.message || "Si è verificato un errore. Ricarica la pagina e riprova.");
            setDeleteFetchStatus('failed');
        }
    }

    // productId, userId, imagesIdArray
    return (
        <div className={`w-full min-h-[152px] flex flex-col md:flex-row md:items-center gap-2 rounded-lg border border-teal-500 py-2 px-4 bg-white ${isCrud && 'lg:hover:bg-slate-100'}`}>
            {/* Image and title */}
            <div className="w-full flex gap-2">
                <div className="flex items-center text-start">
                    <div className="w-24 h-24 overflow-hidden rounded-md">
                        <img src={cover} alt={title} className="h-full w-full object-cover" />
                    </div>
                </div>
                <div className="flex-1 flex items-start justify-center text-start flex-col gap-1 p-2">
                    <div>{title}</div>
                    <CategoryTag cat={category} />
                </div>
            </div>

            {/* Price */}
            <div className="border px-2 h-fit flex items-center justify-center text-start text-3xl">
                {price}€
            </div>


            {/* Tasti edit e delete nel caso isCrud*/}
            {
                isCrud &&
                <div className="w-96 flex items-center justify-end text-start gap-2 p-2">
                    {!isEditing && !isDeleting && <div><i className="fi fi-rr-pen-square text-5xl text-slate-300 mb-[-10px] cursor-pointer" onClick={() => setIsEditing(true)}></i></div>}
                    {!isEditing && !isDeleting && <div><i className="fi fi-br-cross-circle text-5xl text-red-300 mb-[-10px] cursor-pointer" onClick={() => setIsDeleting(true)}></i></div>}
                    {
                        isEditing &&
                        <div className="flex flex-row md:flex-col gap-2">
                            <PrimaryButton text="Conferma" click={handleEditing} />
                            <SecondaryButton text="Annulla" click={() => setIsEditing(false)} />
                        </div>
                    }
                    {
                        isDeleting && deleteFetchStatus === 'idle' &&
                        <div className="flex flex-row md:flex-col gap-2">
                            <DangerButton text="Conferma" click={sendDelete} />
                            <SecondaryButton text="Annulla" click={() => setIsDeleting(false)} />
                        </div>
                    }
                    {
                        isDeleting && deleteFetchStatus === 'loading' &&
                        <FetchLoader />
                    }
                    {
                        isDeleting && deleteFetchStatus === 'failed' &&
                        <h5>{deleteError}</h5>
                    }
                    {
                        isDeleting && deleteFetchStatus === 'succeeded' &&
                        <h5>Prodotto eliminato con successo. Attendi il refresh della pagina.</h5>
                    }
                </div>
            }

        </div>
    )
}

export default MainCard