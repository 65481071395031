import CategoryTag from "./CategoryTag";
import ProductAvatar from "./ProductAvatar";

const ProductMiniCard = ({ photo, title, category, price, click, isMine }) => {
    return (
        <div className={`flex items-center gap-2 border-2 p-3 rounded-xl min-w-[300px] ${!isMine ? 'hover:border-teal-400' : ''} relative group`}>
            <div>
                <ProductAvatar url={photo} />
            </div>
            <div className="flex flex-col gap-2 items-start">
                <h4>{price.toFixed(2)}€</h4>
                <h4>{title}</h4>
                <CategoryTag cat={category} />
            </div>
            {!isMine && <div className="hidden group-hover:block absolute bottom-1 right-1 p-1 rounded-lg text-white bg-teal-400 text-xs">Info</div>}
        </div>
    )
}

export default ProductMiniCard;