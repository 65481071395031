import { useSelector } from "react-redux";
import { GoHomeButton, PrimaryButton } from "../components/Buttons";
import { useEffect, useState } from "react";
import FetchLoader from '../components/FetchLoader.jsx';
import { Link } from "react-router-dom";

const EnablePositionSupport = () => {
    return (
        <div className="main-container">
            <GoHomeButton />
            <h2 className="mb-4">Riattivare la geolocalizzazione</h2>
            <div className="box md:shadow-2xl max-w-[600px]">
                <h3>Google Chrome</h3>
                <li>Apri Chrome e vai su "impostazioni" tramite il menua a tre punti in alto a destra</li>
                <li>Scorri verso il basso e clicca su "Avanzate".</li>
                <li>Nella sezione "Privacy e sicurezza", trova "Impostazioni contenuti" e clicca su "Posizione".</li>
                <li>Attiva l'opzione "Chiedi prima di accettare la posizione" o seleziona un sito specifico e consenti la posizione.</li>
                <hr />
                <h3>Mozilla Firefox</h3>
                <li>Apri Firefox e vai su "Opzioni" nel menu principale.</li>
                <li>Seleziona "Privacy & Sicurezza".</li>
                <li>Scorri verso il basso fino a "Autorizzazioni" e trova "Posizione".</li>
                <li>Seleziona l'opzione "Chiedi per attivare la posizione" o gestisci le eccezioni per siti specifici.</li>
                <hr />
                <h3>Safari (su macOS):</h3>
                <li>Vai su "Preferenze" tramite il menu Safari.</li>
                <li>Seleziona la scheda "Privacy".</li>
                <li>Trova la sezione "Blocca i siti Web da accedere a Posizione" e assicurati che non ci siano siti elencati lì.</li>
                <li>Riabilita la geolocalizzazione se necessario.</li>
                <hr />
                <h3>iOS (iPhone/iPad):</h3>
                <li>Vai su "Impostazioni" e seleziona "Privacy".</li>
                <li>Tocca "Servizi di localizzazione" e assicurati che sia abilitato.</li>
                <li>Scorri verso il basso per trovare il browser specifico (ad esempio, Safari) e assicurati che sia impostato su "Mentre usi l'app" o "Sempre".</li>
                <hr />
                <h3>Android:</h3>
                <li>Apri le "Impostazioni" del tuo dispositivo.</li>
                <li>Scorri verso il basso e seleziona "Applicazioni" o "App" (il nome potrebbe variare a seconda del dispositivo).</li>
                <li>Trova il browser che stai utilizzando (ad esempio, Chrome) e toccalo.</li>
                <li>Vai su "Autorizzazioni" o "Autorizzazioni dell'app" e assicurati che la posizione sia abilitata.</li>
                <hr />
                <p>Seguendo questi passaggi, dovresti essere in grado di riattivare la geolocalizzazione sui tuoi dispositivi e browser. </p>

                <p>Se riscontri ancora problemi con la geolocalizzazioneper il tuo browser, puoi <Link to="/support" className="text-teal-500 underline">contattare l'assistenza</Link>.</p>

                <p>Grazie, Il Team di Assistenza <span className="text-teal-500"><b>Tattooswap</b></span></p>

            </div>
        </div>
    )
}
export default EnablePositionSupport;