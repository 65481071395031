import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './loginSlice.js';
import positionReducer from './positionSlice.js';
import productsReducer from './productsSlice.js';
import pageReducer from './pageSlice.js';
import mySectionReducer from './mySectionSlice.js';
import adminReducer from './adminSlice.js';
import conversationsReducer from './conversationsSlice.js';
import filtersReducer from './filtersSlice.js';
import homepageReducer from './homepageSlice.js';
import mapProductsReducer from './mapProductsSlice.js';
import mapFiltersReducer from './mapFilterSlice.js';
import userOriginReducer from './userOriginSlice.js';

const store = configureStore({
    reducer: {
        login: loginReducer,
        homepage: homepageReducer,
        products: productsReducer,
        filters: filtersReducer,
        admin: adminReducer,
        position:  positionReducer,
        page: pageReducer,
        mySection: mySectionReducer,
        conversations: conversationsReducer,
        mapProducts: mapProductsReducer,
        mapFilters: mapFiltersReducer,
        userOrigin: userOriginReducer
    }
})

export default store;