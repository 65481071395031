import { useDispatch, useSelector } from "react-redux";
import { TealButton } from "./Buttons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setNewFilters } from "../redux/filtersSlice";


// Questa SearchBar rimanda alla pagina AllProducts se ad essa non viene passato il setFilters
// Nel caso il setFilters venga passato come prop, la SearchBar reimposterà i parametri di filters invece di utilizzare il navigate
const SearchBar = ({ firstInput, isFromHome }) => {
    const token = localStorage.getItem('tattooswap_auth_token');

    // Handle input data
    const [inputData, setInputData] = useState(firstInput ? firstInput : '');
    const handleInputData = (event) => {
        const value = event.target.value;
        setInputData(value);
    }

    // Al click sul button aggiorno 'filters' di Redux e mando l'utente in AllProducts
    // AllProducts si setterà già ascoltando 'filters' di Redux
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToResultPage = () => {
        dispatch(setNewFilters({ search: inputData }));
            navigate('/all-products')
    }

    // Gestisco il tasto invio per eseguire la ricerca
    const handleKeyDown = (event) => {
        if(event.key === "Enter") {
            goToResultPage();
        }
    }

    return (
        <div className="flex flex-col md:flex-row gap-4 p-4 items-center rounded-xl md:shadow-xl">
            <div className="flex gap-2">
                <i className="fi fi-br-search text-2xl text-slate-300 mt-3"></i>
                <input type="text" className="w-80" placeholder="Cerca un prodotto" onChange={handleInputData} value={inputData} onKeyDown={handleKeyDown}/>
            </div>
            <TealButton text="Cerca" click={goToResultPage} />
        </div>
    )
}

export default SearchBar;